import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faClock } from "@fortawesome/free-solid-svg-icons";

import messages from './messages';
import { Button } from "react-bootstrap";

const Td = styled.td`
  white-space: nowrap;
  text-decoration: ${(props) => (props.canceled ? 'line-through' : null)};
`;

const FormattedAppointmentTime = ({ appointment, selected, intl, canceled }) => {
  let formattedTime;

  const { formatMessage } = intl;

  if (appointment.gotDelay) {
    formattedTime = (
      <span>
        {appointment.time}&nbsp;<b>({appointment.gotDelay})</b>
      </span>);
  } else if (appointment.sendDelay && appointment.name) {
    const senderTooltipId = appointment.humanIssued ? messages.sendByHuman : messages.sendBySystem;
    const humanIssuedIcon = <span style={{ marginRight: 5 }} title={formatMessage(senderTooltipId)}>
      <FontAwesomeIcon icon={appointment.humanIssued ? faArrowDown : faArrowUp} />&nbsp;
    </span>;

    formattedTime = (
      <span>
        {appointment.time}&nbsp;({appointment.sendDelay})&nbsp;{humanIssuedIcon}
      </span>
    );
  } else if (selected && appointment.name && !canceled) {
    formattedTime = (
      <span>
        {appointment.time}
      </span>
    );
  } else {
    formattedTime = (<span>{appointment.time}</span>);
  }

  return formattedTime;
};

FormattedAppointmentTime.propTypes = {
  appointment: PropTypes.object.isRequired,
  intl: PropTypes.object,
  selected: PropTypes.bool,
  canceled: PropTypes.bool,
};

const FormattedTimeTd = ({ appointment, currentDelay, selected, notifyDelay, intl, canceled }) => {
  const minDelay = 15;
  const systemHasDelay = currentDelay > minDelay;

  const sendNoDelay = !systemHasDelay && appointment.sendDelay > minDelay;
  const sendDelay = systemHasDelay && appointment.sendDelay !== currentDelay;

  const formattedAppointmentTime = (
    <FormattedAppointmentTime appointment={appointment} selected={selected} intl={intl} canceled={canceled} />);

  const { formatMessage } = intl;

  const delayButton = (
    <Button
      title={formatMessage(messages.sendMessageButton)}
      variant="light"
      size="sm"
      onClick={notifyDelay}
    >
      <FontAwesomeIcon icon={faClock} />
    </Button>
  );

  const contentWithDelay = (<span>{ delayButton }&nbsp;{ formattedAppointmentTime }</span>);
  const contentWithoutDelay = (<span>{ formattedAppointmentTime }</span>);

  const tdContent = !canceled && appointment.name && (sendNoDelay || sendDelay) ? contentWithDelay : contentWithoutDelay;

  return (
    <Td
      canceled={canceled}
      style={{ minWidth: 105 }}
    >
      <div style={{ minHeight: 25 }}>
        {tdContent}
      </div>
    </Td>);
};

FormattedTimeTd.propTypes = {
  appointment: PropTypes.object.isRequired,
  intl: PropTypes.object,
  selected: PropTypes.bool,
  notifyDelay: PropTypes.func,
  currentDelay: PropTypes.number,
  canceled: PropTypes.bool,
};

export default injectIntl(FormattedTimeTd);
