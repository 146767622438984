import React from "react";
import { FormattedMessage, FormattedRelativeTime, injectIntl } from "react-intl";
import { selectUnit } from "@formatjs/intl-utils";
import styled from "styled-components";
import PropTypes from "prop-types";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import messages from './messages';
import { timerActions } from '../timer';

const Td = styled.td`
    white-space: nowrap;
    text-decoration: ${props => (props.canceled ? "line-through" : null)};
`;

const Tr = styled.tr`
    background-color: ${props => (props.selected ? "#CBE8FE" : null)} !important;
`;

class UrgentPatientsRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleDisabled = this.handleDisabled.bind(this);

        this.state = {};
    }

    handleDisabled(event) {
        const { target } = event;
        const { urgentPatient, onDisable } = this.props;
        const disabled = !target.checked;

        if (urgentPatient.disabled === disabled) {
            return;
        }

        onDisable(disabled);
    }

    render() {
        const { urgentPatient, index, onDelete } = this.props;

        const statusArgs = (urgentPatient || {}).status_args || {};

        if (statusArgs.at) {
            statusArgs.at = timerActions.asLocalTimeIsrael(Date.parse(statusArgs.at)).format('HH:mm');
        }

        let status;
        if (urgentPatient.status) {
            status = (<FormattedMessage {...messages[ urgentPatient.status ]} values={statusArgs}/>);
        }

        const deleteButton = (
            <Button
                onClick={onDelete}
                variant="light"
                size="sm"
            >
                <FontAwesomeIcon icon={faTrash}/>
            </Button>
        );

        const { value, unit } = selectUnit(Date.parse(urgentPatient.updated_at), Date.now(), { day: 13 });

        return (
            <Tr>
                <th
                    style={{ width: '60px' }}
                    scope="row"
                >
                    <input
                        type="checkbox"
                        checked={!urgentPatient.disabled}
                        onChange={this.handleDisabled}
                    />
                    &nbsp;{index + 1}
                </th>
                <Td style={{ width: '10px' }}>{deleteButton}</Td>
                <Td>{urgentPatient.name}</Td>
                <Td>{urgentPatient.id}</Td>
                <Td>{urgentPatient.mobile_number}</Td>
                <Td>
                    <FormattedRelativeTime value={value} unit={unit}/>
                </Td>
                <Td>{urgentPatient.comments}</Td>
                <Td>{status}</Td>
            </Tr>
        );
    }
}

UrgentPatientsRow.propTypes = {
    urgentPatient: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onDelete: PropTypes.func,
    onDisable: PropTypes.func,
};

export default injectIntl(UrgentPatientsRow);
