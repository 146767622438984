import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.PatientsPage.header',
    defaultMessage: 'תוכנית',
  },
  headerToday: {
    id: 'app.containers.PatientsPage.headerToday',
    defaultMessage: 'תוכנית להיום',
  },
});
