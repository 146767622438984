import produce from 'immer';
import { authActions } from './actions';

export const initialState = {
    doctor: undefined,
};

const authReducer = (state = initialState, action) =>
    produce(state, draft => {
        if (action.type === authActions.SET_DOCTOR) {
            draft.doctor = action.doctor;
        }
    });

export default authReducer;
