import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import classNames from 'classnames/bind';
import messages from './messages';
import PropTypes from 'prop-types';

const DelayOption = ({ delay, formatMessage }) => {
  if (delay === 0) {
    return (
      <option value={delay.toString()}>{formatMessage(messages.noDelay)}</option>
    );
  }

  return (<option value={delay.toString()}>{formatMessage(messages.delayUpTo, { minutes: delay })}</option>);
};

DelayOption.propTypes = {
  delay: PropTypes.number,
  formatMessage: PropTypes.func.isRequired,
};

class DelaySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { currentDelay: props.currentDelay || 0 };
    this.handleDelayChange = this.handleDelayChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ currentDelay: nextProps.currentDelay || 0 });
  }

  handleDelayChange(event) {
    const currentDelay = parseInt(event.target.value, 10);
    this.props.onChange(currentDelay);
  }

  render() {
    const { intl, disabled } = this.props;
    const { formatMessage } = intl;

    const updateDelayButtonClasses = classNames({ disabled });

    return (
      <div style={{ display: 'inline-block' }}>
        <select className={updateDelayButtonClasses} value={this.state.currentDelay} name="currentDelay" onChange={this.handleDelayChange}>
          <DelayOption delay={0} formatMessage={formatMessage} />
          <DelayOption delay={15} formatMessage={formatMessage} />
          <DelayOption delay={30} formatMessage={formatMessage} />
          <DelayOption delay={45} formatMessage={formatMessage} />
          <DelayOption delay={60} formatMessage={formatMessage} />
        </select>
      </div>
    );
  }
}

DelaySelect.propTypes = {
  intl: PropTypes.object,
  currentDelay: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default injectIntl(DelaySelect);
