import { call, fork, take, put, race } from 'redux-saga/effects';
import { timerActions } from './actions';

// wait :: Number -> Promise
const wait = (ms) => (
  new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  })
);

function* runTimer() {
  yield put(timerActions.start());

  // noinspection InfiniteLoopJS
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const winner = yield race({
      stopped: take('STOP'),
      tick: call(wait, timerActions.secondsUntilDayChange() * 1000),
    });

    if (!winner.stopped) {
      yield put(timerActions.dayChange());
    } else {
      break;
    }
  }
}

export function* timerSagas() {
  yield fork(runTimer);
}
