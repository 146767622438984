/* global API_URL */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from "reselect";
import { firebaseConnect } from "react-redux-firebase";
import { Badge, Button, Container, Form } from "react-bootstrap";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import messages from './messages';

const selectDoctor = () => (state) => state.auth.doctor;

const mapStateToProps = createSelector(
  selectDoctor(),
  (doctor) => ({
    doctor,
  })
);

@connect(mapStateToProps)
@firebaseConnect()
export default class SystemMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { changed: false, text: props.message.text };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.loadPreview = this.loadPreview.bind(this);
  }

  async loadPreview() {
    const { firebase, doctor } = this.props;

    const accessToken = await firebase.auth().currentUser.getIdToken();
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}/${doctor.id}`,
    };

    await fetch(`${API_URL}/message/preview`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        text: this.state.text,
      }),
    })
      .then((response) => response.json())
      .then((result) => { this.setState({ previewText: result.text }); })
      .catch(() => {});
  }

  togglePreview() {
    const { previewText } = this.state;

    if (previewText) {
      this.setState({ previewText: '' });
      return;
    }

    this.loadPreview();
  }

  handleChange(event) {
    this.setState({ changed: true, text: event.target.value });

    const { previewText } = this.state;

    if (previewText) {
      this.loadPreview();
    }
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({ updating: true });

    const { doctor, firebase } = this.props;

    const accessToken = await firebase.auth().currentUser.getIdToken();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}/${doctor.id}`,
    };

    await fetch(`${API_URL}/message`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        text: this.state.text,
        messageId: this.props.messageId,
      }),
    })
      .then(() => { this.setState({ changed: false }); })
      .catch(() => {})
      .then(() => { this.setState({ updating: false }); });
  }

  render() {
    const message = this.state.text;
    const messageId = this.props.messageId;
    const { previewText } = this.state;
    const { doctor } = this.props;

    let msg;
    if (messages[messageId]) {
      msg = (<FormattedMessage {...messages[messageId]} />);
    } else {
      msg = messageId;
    }

    let sourceTag;
    if (_.startsWith(messageId, 'facebook')) {
      sourceTag = <Badge variant="secondary" style={{ backgroundColor: '#3b5998' }}>facebook</Badge>;
    } else {
      sourceTag = <Badge variant="secondary">SMS</Badge>;
    }


    let preview;
    if (previewText) {
      preview = (
        <span className="mark" dangerouslySetInnerHTML={{ __html: previewText }} />
      );
    }

    const showPreview = !!previewText;

    return (
      <Container>
        <div className="col-md-6 pull-right">
          <Form.Label htmlFor={messageId} className="form-check-label pull-right">
            <b>{msg}:</b>
          </Form.Label>

          <Form.Label htmlFor={messageId} className="float-right">
            {sourceTag}
          </Form.Label>

          <Form.Control as="textarea"  rows="4" style={{ width: '100%' }} name={messageId} value={message} onChange={this.handleChange} />
          <br />
          <Button disabled={this.state.updating || !this.state.changed} type="submit" onClick={this.handleSubmit} className="btn btn-primary">עדכן</Button>&nbsp;
          <Button className="btn btn-default pull-left" onClick={this.togglePreview}>
            <FontAwesomeIcon icon={showPreview ? faEyeSlash : faEye}/>
          </Button>
          <br />
          <br />
          {preview}
          <hr />
        </div>
      </Container>
    );
  }
}

SystemMessage.propTypes = {
  message: PropTypes.object.isRequired,
  messageId: PropTypes.string.isRequired,
};
