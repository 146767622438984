/* global API_URL */
import React from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { firebaseConnect } from "react-redux-firebase";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from 'classnames/bind';
import { createSelector } from "reselect";
import _ from 'lodash';
import { Button, ButtonToolbar, Container, Row, ToggleButton, ToggleButtonGroup } from "react-bootstrap";

import { timerActions } from "../timer/actions";
import messages from './messages';
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";


class Providers {
    static createValidateOrSaveProvider = (firebase, provider, save) => {
        return async (doctor, username, password, doctor_username, address, days, clinics) => {
            const accessToken = await firebase.auth().currentUser.getIdToken();
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}/${doctor.id}`,
            };

            const login = { username, password, doctor_username, clinics };

            const api_call = await fetch(`${API_URL}/service/checkOrSave/${provider}`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    login,
                    address,
                    save,
                    days,
                }),
            });

            if (api_call.ok) {
                return await api_call.json()
            }
        }
    };
}

const selectFirebase = () => state => state.firebase;
const selectDoctor = () => state => state.auth.doctor;

const mapStateToProps = createSelector(
    selectFirebase(),
    selectDoctor(),
    (firebase, doctor) => ({
        doctor,
    })
);

@connect(mapStateToProps)
@firebaseConnect()
class UserPasswordRegisterView extends React.Component {
    constructor(props) {
        super(props);

        const { prefix } = props;

        this.state = {
            validUserPassword: null,
            waitingForAPI: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.doSave = this.doSave.bind(this);
        this.doValidate = this.doValidate.bind(this);
        this.getFromStateOrDoctor = this.getFromStateOrDoctor.bind(this);
        this.handlePrefixChange = this.handlePrefixChange.bind(this);
        this.getFromStateOrAddress = this.getFromStateOrAddress.bind(this);
        this.getFromStateOrDays = this.getFromStateOrDays.bind(this);
        this.getActualPrefix = this.getActualPrefix.bind(this);
        this.getPrefixStateName = this.getPrefixStateName.bind(this);
        this.getClinics = this.getClinics.bind(this);
    }

    handleInputChange = (event) => {
        const target = event.target;

        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (target.type === 'select-one') {
            const selectValue = this.state[ name ];
            selectValue.selected = value;
            value = selectValue;
        }

        this.setState({
            [ name ]: value,
            validUserPassword: null,
        });

        if (this.props.onStateChange) {
            this.props.onStateChange(name, value);
        }
    };

    handlePrefixChange = (event) => {
        const target = event.target;

        this.setState({
            fullPrefix: target.value,
            validUserPassword: null,
        });
    };

    validateOrSaveProvider = (doSave) => {
        return async (event) => {
            event.preventDefault();

            const { doctor } = this.props;

            const username = this.getFromStateOrDoctor('username', true);
            const password = this.getFromStateOrDoctor('password', true);
            const doctor_username = this.getFromStateOrDoctor('doctor_username', true);
            const clinics = this.getClinics();
            const address = this.getFromStateOrAddress();
            const days = this.getFromStateOrDays();

            const actualPrefix = this.getActualPrefix(false);

            const validator = Providers.createValidateOrSaveProvider(this.props.firebase, actualPrefix, doSave);

            let result = await validator(doctor, username, password, doctor_username, address, days, clinics);

            const getShortPrefixStateName = (name) => this.getPrefixStateName(name, true);

            if (result.missing) {
                result = _.merge(_.mapKeys(result.missing, function (value, key) {
                    return getShortPrefixStateName(key);
                }), result);

                result.hasMissing = true;

                delete result.missing;
            }

            return result;
        };
    };

    async doValidate(event) {
        const result = await this.validateOrSaveProvider(false)(event);

        const isValid = result[ 'ok' ];
        this.setState(_.merge(result, { validUserPassword: isValid }));

    }

    async doSave(event) {
        this.setState({
            waitingForAPI: true
        });

        const result = await this.validateOrSaveProvider(true)(event);

        const isValid = result[ 'ok' ];
        const hasMissing = result.hasMissing;

        this.setState(_.merge(result, { validUserPassword: isValid || hasMissing, waitingForAPI: false }));
    }

    getFromState = (propName, shortPrefix) => {
        let val = this.state[ this.getPrefixStateName(propName, shortPrefix) ];
        if (val !== undefined) {
            return val;
        }
    };

    getActualPrefix = (shortPrefix) => {
        const { fullPrefix } = this.state;
        const { prefix } = this.props;

        let actualPrefix = fullPrefix || prefix;

        if (shortPrefix) {
            actualPrefix = actualPrefix.split('_')[ 0 ]
        }

        return actualPrefix;
    };

    getFromStateOrDoctor = (propName, shortPrefix) => {
        const val = this.getFromState(propName, shortPrefix);
        const { doctor } = this.props;

        const actualPrefix = this.getActualPrefix(shortPrefix);

        if (val !== undefined) {
            return val;
        }

        return (((doctor || {}).sync_sources || {})[ actualPrefix ] || {})[ propName ];
    };

    getClinics = () => {
        return this.getFromStateOrDoctor('clinics', true);
    };

    getClinic = () => {
        const clinics = this.getClinics() || [];
        const id = this.getActualPrefix(false);

        return _.find(clinics, { id });
    };

    getFromStateOrAddress = () => {
        const val = this.getFromState('address', false);
        const { doctor } = this.props;

        if (!!val) {
            return val;
        }

        const syncSourcesPerAddress = ((doctor || {}).sync_sources_per_address || {});

        let address = undefined;

        const actualPrefix = this.getActualPrefix();

        _.forOwn(syncSourcesPerAddress, (syncSources, addressName) => {
            if (syncSources.indexOf(actualPrefix) === -1) {
                return;
            }

            address = addressName;
        });

        address = ((doctor || {}).addresses || {})[ address ];
        if (!address) {
            const clinic = this.getClinic();

            if (clinic) {
                address = clinic.address;
            }
        }

        return address;
    };

    getFromStateOrDays = () => {
        const val = this.getFromState('days');
        const { doctor } = this.props;

        if (!!val) {
            return val;
        }

        const days = [];
        const actualPrefix = this.getActualPrefix(false);

        const syncSourceDays = ((doctor || {}).sync_source_days || {});
        _.forOwn(syncSourceDays, (syncSources, day) => {
            if (!syncSources || syncSources.indexOf(actualPrefix) === -1) {
                return;
            }

            days.push(day)
        });

        return days;
    };

    getPrefixStateName = (name, shortPrefix) => {
        return name + '_' + this.getActualPrefix(shortPrefix);
    };

    render() {
        const { service, askForValue, isInline, onClose, disableCheckButton, intl } = this.props;
        const { fullPrefix } = this.state;

        const hasServicePrefixName = this.getPrefixStateName('has', true);
        const { waitingForAPI } = this.state;

        const readOnly = (!this.state[ hasServicePrefixName ] && askForValue) || waitingForAPI;

        const showElements = !askForValue;

        const username = this.getFromStateOrDoctor('username', true);
        const password = this.getFromStateOrDoctor('password', true);
        const doctor_username = this.getFromStateOrDoctor('doctor_username');
        const clinics = this.getFromStateOrDoctor('clinics', true);
        const address = this.getFromStateOrAddress();
        const days = this.getFromStateOrDays();

        const disableCheck = readOnly || (!username || !password);

        const labelClasses = classNames({
            'sr-only': isInline,
            'col-sm-2': !isInline
        });

        const inputClasses = classNames('', {
            'mb-2 mr-sm-2 mb-sm-0': !isInline
        });

        const inputDivClasses = classNames({
            'col-sm-10': !isInline
        });

        const saveButtonClasses = classNames('btn', {
            'btn-primary': this.state.validUserPassword === true || this.state.validUserPassword === null,
            'btn-danger': this.state.validUserPassword === false
        });

        const saveButton = isInline ? (
            <div className="form-group">
                <div className={inputDivClasses}>
                    <Button onClick={this.doSave} className={saveButtonClasses}
                            disabled={waitingForAPI || this.state.validUserPassword === false}>
                        <FontAwesomeIcon icon={faSave}/>
                    </Button>
                </div>
            </div>
        ) : null;

        const closeButton = isInline ? (
            <div className="form-group">
                <div className={inputDivClasses}>
                    <Button variant="warning" disabled={waitingForAPI} onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </Button>
                </div>
            </div>
        ) : null;

        const checkButtonClasses = classNames('', {
            'btn-success': this.state.validUserPassword === true || this.state.validUserPassword === null,
            'btn-danger': this.state.validUserPassword === false
        });

        const checkMarks = this.state.validUserPassword ? <FontAwesomeIcon icon={faCheck}/> : null;
        const checkButton = disableCheckButton ? null : (
            <div className="form-group">
                <div className={inputDivClasses}>
                    <Button className={checkButtonClasses} onClick={this.doValidate}
                            disabled={disableCheck}>Check {checkMarks}</Button>
                </div>
            </div>);


        let clinicsElement;

        if (clinics) {
            const makeOption = function (item) {
                return <option key={item.id} value={item.id}>{item.name}</option>;
            };

            const prefixName = this.getPrefixStateName('clinics', true);

            clinicsElement = (
                <Form.Group>
                    <div className={inputDivClasses}>
                        <Form.Control
                            as="select" name={prefixName} id={prefixName} className={inputClasses}
                            value={fullPrefix}
                            defaultValue={0}
                            key={prefixName}
                            disabled={readOnly}
                            onChange={this.handlePrefixChange}>
                            <option key={0} value='0' disabled={true}
                                    hidden={true}>{intl.formatMessage(messages.pleaseSelect)}</option>
                            ;
                            {clinics.map(makeOption)}
                        </Form.Control>
                    </div>
                    <label className={labelClasses} htmlFor={prefixName}>Clinics</label>
                </Form.Group>
            )
        }

        let doctorUsernameElement;
        if (doctor_username) {
            const makeOption = function (item) {
                return <option key={item[ 0 ]} value={item[ 0 ]}>{item[ 1 ]}</option>;
            };

            const prefixName = this.getPrefixStateName('doctor_username', true);

            doctorUsernameElement = (
                <Form.Group>
                    <div className={inputDivClasses}>
                        <Form.Control as="select" name={prefixName} defaultValue={0} id={prefixName}
                                      className={inputClasses} value={doctor_username.selected} key={prefixName}
                                      onChange={this.handleInputChange} disabled={readOnly}>
                            <option key={0} value='0' disabled={true}
                                    hidden={true}>{intl.formatMessage(messages.pleaseSelect)}</option>
                            ;
                            {doctor_username.options.map(makeOption)}
                        </Form.Control>
                    </div>
                    <Form.Label className={labelClasses} htmlFor={prefixName}>Doctor</Form.Label>
                </Form.Group>
            )
        }

        const weekArray = _.zip(timerActions.daysOfWeek(), timerActions.daysOfWeekNumbers());

        const makeDayToggle = function (item) {
            const dayName = item[ 0 ];
            const dayNumber = item[ 1 ];
            return <ToggleButton value={String(dayNumber)} key={String(dayNumber)}>{dayName}</ToggleButton>;
        };

        const setPrefixState = (name, value, shortPrefix) => {
            const prefixName = this.getPrefixStateName(name, shortPrefix);

            this.setState({ [ prefixName ]: value })
        }

        const daysElements = (
            <ButtonToolbar className="pull-right">
                <ToggleButtonGroup type="checkbox" value={days} onChange={days => setPrefixState('days', days, false)}>
                    {weekArray.map(makeDayToggle)}
                </ToggleButtonGroup>
            </ButtonToolbar>
        );

        const usernamePrefixName = this.getPrefixStateName('username', true);
        const passwordPrefixName = this.getPrefixStateName('password', true);
        const addressPrefixName = this.getPrefixStateName('address', false);

        const hasFullPrefix = !!this.state.fullPrefix || this.getActualPrefix() === 'odoro';

        const prefixedItem = hasFullPrefix ? (
            <Container>
                <Row style={{ marginTop: "0.5em" }}>
                    <Form.Group>
                        <div className={inputDivClasses}>
                            <Form.Control data-lpignore="true" name={addressPrefixName} id={addressPrefixName}
                                          className={inputClasses}
                                          placeholder={intl.formatMessage(messages.address)} readOnly={readOnly}
                                          autoComplete="off" value={address}
                                          key={addressPrefixName}
                                          onChange={this.handleInputChange} style={{ width: "39em" }}/>
                        </div>
                        <Form.Label className={labelClasses} htmlFor={passwordPrefixName}>Password</Form.Label>
                    </Form.Group>
                </Row>
                <Row style={{ marginTop: "0.5em" }}>
                    {daysElements}
                </Row>
            </Container>
        ) : null;

        const elements = showElements ?
            (
                <Container>
                    <Row>
                        <Form.Group>
                            <div className={inputDivClasses}>
                                <Form.Control data-lpignore="true" name={usernamePrefixName} id={usernamePrefixName}
                                              className={inputClasses}
                                              placeholder={intl.formatMessage(messages.username)} readOnly={readOnly}
                                              autoComplete="off" value={username}
                                              key={usernamePrefixName}
                                              onChange={this.handleInputChange}/>
                            </div>
                            <Form.Label className={labelClasses}
                                        htmlFor={usernamePrefixName}><FormattedMessage {...messages.username} /></Form.Label>
                        </Form.Group>
                        <Form.Group>
                            <div className={inputDivClasses}>
                                <Form.Control data-lpignore="true" name={passwordPrefixName} id={passwordPrefixName}
                                              type="password" className={inputClasses}
                                              placeholder={intl.formatMessage(messages.password)} readOnly={readOnly}
                                              autoComplete="off" value={password}
                                              key={passwordPrefixName}
                                              onChange={this.handleInputChange}/>
                            </div>
                            <Form.Label className={labelClasses}
                                        htmlFor={passwordPrefixName}><FormattedMessage {...messages.password} /></Form.Label>
                        </Form.Group>
                        {doctorUsernameElement}
                        {clinicsElement}
                        {checkButton}
                        {saveButton}&nbsp;
                        {closeButton}
                        {prefixedItem}
                    </Row>
                </Container>
            ) : null;

        const askForValueElement = askForValue ? (
            <Form.Group>
                <div className="col-sm-offset-2 col-sm-8">
                    <Form.Check type="checkbox">
                        <Form.Label htmlFor={hasServicePrefixName}>
                            <FormattedMessage {...messages.askForSource} values={{ service }}/>
                        </Form.Label>
                        <Form.Control name={hasServicePrefixName} id={hasServicePrefixName} type="checkbox"
                                      checked={this.state[ hasServicePrefixName ]}
                                      onChange={this.handleInputChange}/>
                    </Form.Check>
                </div>
            </Form.Group>
        ) : null;

        return (
            <div>
                <Form inline>
                    {askForValueElement}
                    {elements}
                </Form>
            </div>
        )
    }
}

UserPasswordRegisterView.propTypes = {
    askForSource: PropTypes.bool,
    prefix: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
};

export default injectIntl(UserPasswordRegisterView);
