/* eslint-disable no-constant-condition */
import { replace, push } from 'connected-react-router';
import { call, fork, put, take} from 'redux-saga/effects';
import { authActions } from './actions';


function firebaseSignIn(firebase, authProvider) {
  //return firebase.auth().signInWithPopup(authProvider);
}

function firebaseSignout(firebase) {
  return firebase.auth().signOut();
}

function* sagaSignIn(firebase, authProvider) {
  try {
    console.log('sagaSignIn');
    const authData = yield call(firebaseSignIn, firebase, authProvider);
    yield put(authActions.signInFulfilled(authData.user));
    yield put(push('/'));
  } catch (error) {
    yield put(authActions.signInFailed(error));
  }
}

function* sagaSignOut(firebase) {
  try {
    console.log('sagaSignOut');
    yield call(firebaseSignout, firebase);
    yield put(authActions.signOutFulfilled());
    yield put(replace('/'));
  } catch (error) {
    yield put(authActions.signOutFailed(error));
  }
}


function* watchSignIn() {
  // noinspection InfiniteLoopJS
  while (true) {
    console.info('watchSignIn() {')
    const { payload } = yield take(authActions.SIGN_IN);

    yield fork(sagaSignIn, payload.firebase, payload.authProvider);
  }
}

function* watchSignOut() {
  // noinspection InfiniteLoopJS
  while (true) {
    console.info('watchSignOut() {')
    const { payload } = yield take(authActions.SIGN_OUT);

    yield fork(sagaSignOut, payload.firebase);
  }
}


export function* authSagas() {
  console.info('authSagas() {')
  yield fork(watchSignIn);
  yield fork(watchSignOut);
}
