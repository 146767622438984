/*
 * UrgentAppointment Messages
 *
 * This contains all the text for the DayTable component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  add: {
    id: 'app.components.UrgentAppointment.add',
    defaultMessage: 'הוספה',
  },
});
