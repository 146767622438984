/*
 * DayTable Messages
 *
 * This contains all the text for the DayTable component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  updateList: {
    id: 'app.components.UploadControl.updateList',
    defaultMessage: 'עדכן',
  },
  importOdoro: {
    id: 'app.components.UploadControl.importOdoro',
    defaultMessage: 'אודורו',
  },
  importMaccabi: {
    id: 'app.components.UploadControl.importMaccabi',
    defaultMessage: 'מכבי',
  },
  sendTime: {
    id: 'app.components.UploadControl.sendTime',
    defaultMessage: 'שעת שליחה',
  },
  noImportSources: {
    id: 'app.components.ImportSources.noImportSources',
    defaultMessage: 'הגדרות כניסה',
  },
  importFrom: {
    id: 'app.components.ImportSources.importFrom',
    defaultMessage: 'הגדרות משתמש {importSource}',
  },
  messagesSettings: {
    id: 'app.components.ImportSources.messagesSettings',
    defaultMessage: 'הגדרות הודעות',
  },
});
