/*
 * UrgentAppointment Messages
 *
 * This contains all the text for the DayTable component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  columnName: {
    id: 'app.components.UrgentPatients.columnName',
    defaultMessage: 'שם',
  },

  columnPhone: {
    id: 'app.components.UrgentPatients.columnPhone',
    defaultMessage: 'טלפון'
  },

  columnStatus: {
    id: 'app.components.UrgentPatients.columnStatus',
    defaultMessage: 'סטטוס',
  },

  columnId: {
    id: 'app.components.UrgentPatients.columnId',
    defaultMessage: 'ת.ז.',
  },

  columnComments: {
    id: 'app.components.UrgentPatients.columnComment',
    defaultMessage: 'הערות',
  },

  add: {
    id: 'app.components.UrgentPatients.add',
    defaultMessage: 'הוספה',
  },

  send: {
    id: 'app.components.UrgentPatients.send',
    defaultMessage: 'שליחה',
  },

  newPatient: {
    id: 'app.components.UrgentPatients.newPatient',
    defaultMessage: 'פרטי תור דחוף',
  },

  save: {
    id: 'app.components.UrgentPatients.save',
    defaultMessage: 'שמור',
  },

  close: {
    id: 'app.components.UrgentPatients.close',
    defaultMessage: 'סגור',
  },

  empty: {
    id: 'app.components.UrgentPatients.empty',
    defaultMessage: 'רשימת מטופלים דחופים ריקה',
  },
  waitingForUrgentWelcomeSms: {
    id: 'app.components.DayTable.waitingForUrgentWelcomeSms',
    defaultMessage: 'תורים ישלחו ב{at}',
  },
  urgentWelcomeSmsSent: {
    id: 'app.components.UrgentPatients.urgentWelcomeSmsSent',
    defaultMessage: 'תורים נשלחו ב{at} ({sent_hours})',
  },
  urgentWelcomeSmsSentEmpty: {
    id: 'app.components.UrgentPatients.urgentWelcomeSmsSentEmpty',
    defaultMessage: 'תורים נשלחו ב{at} (לא נשארו תורים)',
  },
});
