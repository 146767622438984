/*
 * DayTable Messages
 *
 * This contains all the text for the DayTable component.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.components.DayTable';

export default defineMessages({
  sendList: {
    id: `${scope}.sendList`,
    defaultMessage: 'שלח תזכורת',
  },
  clearList: {
    id: `${scope}.clearList`,
    defaultMessage: 'נקה רשימה',
  },
  noAppointmentsYet: {
    id: `${scope}.noAppointmentsYet`,
    defaultMessage: 'לא הועלו פגישות להיום',
  },
  noWelcomeSms: {
    id: `${scope}.noWelcomeSms`,
    defaultMessage: 'לא נשלחה תזכורת',
  },
  waitingForWelcomeSms: {
    id: `${scope}.waitingForWelcomeSms`,
    defaultMessage: 'תזכורת תשלח ב{at}',
  },
  welcomeSmsSent: {
    id: `${scope}.welcomeSmsSent`,
    defaultMessage: 'תזכורת נשלחה ב{at}',
  },
  currentDelay: {
    id: `${scope}.currentDelay`,
    defaultMessage: 'עיכוב',
  },
  delayUpTo: {
    id: `${scope}.delayUpTo`,
    defaultMessage: 'עד {minutes} דקות',
  },
  noDelay: {
    id: `${scope}.noDelay`,
    defaultMessage: 'אין כרגע עיכוב',
  },
  updateDelay: {
    id: `${scope}.updateDelay`,
    defaultMessage: 'עדכן',
  },
  sendByHuman: {
    id: `${scope}.sendByHuman`,
    defaultMessage: 'הודעה נשלחה ע"י המשתמש',
  },
  sendBySystem: {
    id: `${scope}.sendBySystem`,
    defaultMessage: 'הודעה נשלחה אוטומטית ע"י המערכת',
  },
  sendMessageButton: {
    id: `${scope}.sendMessageButton`,
    defaultMessage: 'שלח הודעת עיכוב',
  },
  canceledBySms: {
    id: `${scope}.canceledBySms`,
    defaultMessage: 'מטופל ביטל דרך סמס ב{at}',
  },
  sentImHereReminder: {
    id: `${scope}.sentImHereReminder`,
    defaultMessage: 'נשלחה תזכורת לאישור הגעה ב{at}',
  },
  sentImHereEnter: {
    id: `${scope}.sentImHereEnter`,
    defaultMessage: 'מטופל התבקש להכנס ב{at}',
  },
  appointmentEnded: {
    id: `${scope}.appointmentEnded`,
    defaultMessage: 'סיום טיפול ב{at}',
  },
  userAtPlace: {
    id: `${scope}.userAtPlace`,
    defaultMessage: 'מטופל ממתין החל מ{at}',
  },
  userAskedToEnter: {
    id: `${scope}.userAskedToEnter`,
    defaultMessage: 'מטופל נקרא להכנס ב{at}',
  },
  phoneOnlyNoNeedForReminder: {
    id: `${scope}.phoneOnlyNoNeedForReminder`,
    defaultMessage: 'פגישה טלפונית אין צורך בתזכורת',
  },
});
