import 'moment';
import 'moment/locale/he';

const momentTz = require('moment-timezone');
const moment = require('moment');

export const timerActions = {
  START: 'START',
  DAY_CHANGE: 'DAY_CHANGE',

  start: () => ({
    type: timerActions.START,
  }),

  dayChange: () => ({
    type: timerActions.DAY_CHANGE,
  }),

  parseTime: (t) => momentTz.tz(momentTz(t, 'HH:mm').toDate(), 'Israel'),
  formatAsPath: (d) => momentTz(d).format('YYYY/M/D'),
  parseDayMonth: (d) => momentTz.tz(momentTz(d, 'YYYY-MM-DD').toDate(), 'Israel'),
  asLocalTimeIsrael: (d) => momentTz.tz(d, 'Israel'),
  currentTimeInIsrael: () => timerActions.asLocalTimeIsrael(new Date(), 'Israel'),
  daysOfWeek: () => moment.weekdays(),
  daysOfWeekNumbers: () => {
    return [7, 1, 2, 3, 4, 5, 6]; // 7 Sunday, 1 Monday
  },
  dayToIsoDay: (d) => {
    return (d + 6) % 7 + 1;
  },
  isInThePast: () => false,
  isToday: (d) => {
    const todayFormatted = timerActions.formatAsPath(timerActions.currentTimeInIsrael());
    const currentDayFormatted = timerActions.formatAsPath(d);
    return todayFormatted === currentDayFormatted;
  },
  secondsUntilDayChange: () => {
    const now = timerActions.currentTimeInIsrael();
    const midnight = now.clone().endOf('day');

    return +midnight.diff(now, 'seconds') + (3600 * 4); // TODO: hardcoded
  },
};
