import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { firebaseConnect, isLoaded, getVal, isEmpty } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SystemMessage from './SystemMessage';
import messages from './messages';
import Header from '../../components/Header';
import { authActions } from '../../components/auth';
import Container from "react-bootstrap/Container";

const SystemMessages = ({  systemMessages }) => {
  const messagesList = [];

  const systemMessagesSorted = {
    waiting_list: {},
    system_messages: {},
  };

  _.forOwn(systemMessages, (message, key) => {
    let found = false;
    Object.keys(systemMessagesSorted).forEach((groupKey) => {
      if (key.lastIndexOf(groupKey) === 0) {
        systemMessagesSorted[groupKey][key] = message;
        found = true;
      }
    });

    if (!found) {
      systemMessagesSorted.system_messages[key] = message;
    }
  });

  _.forOwn(systemMessagesSorted, (messagesByGroup, groupKey) => {
    messagesList.push((<h3 key={groupKey}><FormattedMessage {...messages[groupKey]} /></h3>));

    _.forOwn(messagesByGroup, (message, key) => {
      messagesList.push((<SystemMessage key={key} messageId={key} message={message} />));
    });
  });

  return (<div>{messagesList}</div>);
};

SystemMessages.propTypes = {
  systemMessages: PropTypes.object.isRequired,
};

const getMessagesPath = (doctorId) => `/messages/${doctorId}`;
const selectFirebase = () => state => state.firebase;
const selectParams = () => (state, props) => props.match.params;

@connect()
class MessagesPageWithDoctor extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { dispatch, doctor } = this.props;
    dispatch(authActions.setDoctor(doctor));
    this.setState({mounted: true})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, doctor } = this.props;
    if (!_.isEqual(prevProps.doctor, doctor)) {
      dispatch(authActions.setDoctor(doctor));
    }
  }

  render() {
    if (!this.state.mounted) {
      return (<div />)
    }

    let body;
    const { systemMessages, doctor } = this.props;

    if (!isLoaded(systemMessages)) {
      body = (<span><FormattedMessage {...messages.loadingSystemMessages} /></span>);
    } else if (isEmpty(systemMessages)) {
      body = (<span><FormattedMessage {...messages.noSystemMessages} /></span>);
    } else {
      body = (<SystemMessages systemMessages={systemMessages} />);
    }

    return (
      <>
        <Header />
        <Container>
          <div className="pb-2 mt-4 mb-2 border-bottom">
            <h1><FormattedMessage {...messages.header} /></h1>
          </div>
          {body}
        </Container>
      </>
    );
  }
}

const getDoctorPath = (doctorId) => `doctors/${doctorId}`;

const selectDoctorId = () => (state, props) => {
  const { match } = props;
  const { doctorId } = match.params;

  return doctorId;
};

const mapStateToProps = createSelector(
  selectFirebase(),
  selectParams(),
  selectDoctorId(),
  (firebase, params, doctorId) => ({
    systemMessages: firebase.data.messages && firebase.data.messages[doctorId],
    doctorId,
    doctor: firebase.data.doctors && firebase.data.doctors[doctorId],
  })
);

@connect(mapStateToProps)
@firebaseConnect((props) => [getDoctorPath(props.doctorId), getMessagesPath(props.doctorId)])
export default class MessagesPage extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { doctor, day, systemMessages } = this.props;

    if (!isLoaded(doctor)) {
      return (<div />);
    }

    return (<MessagesPageWithDoctor systemMessages={systemMessages} doctor={doctor} day={day}/>)
  }
}

MessagesPage.propTypes = {
  match: PropTypes.object,
  systemMessages: PropTypes.object,
};
