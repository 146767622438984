import { authSagas } from '../../components/auth';
import { timerSagas } from '../../components/timer';


export default function* sagas() {
  yield [
    ...authSagas(),
    ...timerSagas(),
  ];
}
