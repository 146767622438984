/* global API_URL */
import { connect } from "react-redux";
import { firebaseConnect } from "react-redux-firebase";
import React from "react";
import { timerActions } from "../timer";
import classNames from "classnames/bind";
import _ from "lodash";
import messages from "./messages";
import { Dropdown, SplitButton, ButtonGroup, Row, Container, Button, DropdownButton } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import UserPasswordRegisterView from "../Settings/userPasswordRegisterView";
import { push } from "connected-react-router";
import { createSelector } from "reselect";

const selectDoctor = () => (state) => state.auth.doctor;

const mapStateToProps = createSelector(
  selectDoctor(),
  (doctor) => ({
    doctor
  })
);


function mapDispatchToProps(dispatch) {
    return ({
        messagesSettings: (doctor) => {
          return () => dispatch(push(`${doctor.id}/messages`))
        }
    })
}

@connect(mapStateToProps, mapDispatchToProps)
@firebaseConnect()
class ImportSources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onClose = this.onClose.bind(this);
    this.importFromSource = this.importFromSource.bind(this);
  }

  onClose = () => {
    this.setState({serviceName: null} )
  };

  async importFromSource(event) {
    const { doctor } = this.props;

    const accessToken = await this.props.firebase.auth().currentUser.getIdToken();

    return fetch(`${API_URL}/importFromSource`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}/${doctor.id}`,
      },
      body: JSON.stringify({
        day: this.props.day,
        messageId: this.props.messageId,
      }),
    })
  }

  getImportSources() {
    const importSources = [];

    const { day, doctor } = this.props;

    const isoDay = timerActions.dayToIsoDay(day.getDay());
    if (doctor.address_per_day) {
      const addressNames = doctor.address_per_day[ isoDay ] || [];

      for (let addressName of addressNames) {
        const syncSources = (doctor.sync_sources_per_address || {})[ addressName ] || [];
        if (syncSources.length === 0) {
          continue;
        }

        const syncSourceName = syncSources[ 0 ];
        importSources.push(syncSourceName)
      }
    }

    return importSources;
  }

  render() {
    const { disabled, intl, doctor, syncSource } = this.props;
    const { serviceName } = this.state;

    const importSources = this.getImportSources();

    const buttonClasses = classNames({
      disabled: disabled,
    });

    const importSourcesLookup = ['odoro', 'maccabi'];

    const getRootSource = (importSource) => {
      return importSource.split('_')[0].toLowerCase();
    };

    const getImportSourceName = (importSource) => {
        const rootSyncSource = getRootSource(importSource);
        const importSourceKey = `import${_.upperFirst(rootSyncSource)}`;

        return intl.formatMessage(messages[importSourceKey]);
    };

    let importSourceNames = [];
    for (const importSource of importSources) {
      importSourceNames.push(getImportSourceName(importSource));
    }

    let importSourceNameTitle;

    let hasImportSource = true;

    if (serviceName) {
      hasImportSource = false;
      importSourceNameTitle = intl.formatMessage(messages.importFrom, { importSource: getImportSourceName(serviceName) })
    } else if (syncSource) {
      importSourceNameTitle = getImportSourceName(syncSource);
    } else if (importSourceNames.length > 0) {
      importSourceNameTitle = importSourceNames.join('/');
    } else {
      hasImportSource = false;
      importSourceNameTitle = intl.formatMessage(messages.noImportSources)
    }

    const menuItems = [];

    for (const importSource of importSourcesLookup) {
        menuItems.push(
          (
            <Dropdown.Item
              onClick={() => this.setState({serviceName: getRootSource(importSource)})}
              key={importSource}>
              <FormattedMessage {...messages.importFrom} values={{ importSource: getImportSourceName(importSource) }} />
            </Dropdown.Item>
          )
        )
    }

    menuItems.push( <Dropdown.Divider key='messagesDivider' />);
    menuItems.push(
        <Dropdown.Item
            as={ButtonGroup}
            onClick={this.props.messagesSettings(doctor)}
            key='messages'>
            <FormattedMessage {...messages.messagesSettings} />
        </Dropdown.Item>
    );


    const button = (
      <SplitButton id="select provider" title={importSourceNameTitle} className={buttonClasses} onClick={hasImportSource ? this.importFromSource : null}>
          {menuItems}
      </SplitButton>
    );

    const userPasswordView = serviceName ? (
      <div style={{background: '#ddd', padding: '0.5em'}}>
        <UserPasswordRegisterView
          disableCheckButton={true}
          onClose={ this.onClose }
          isInline={ true }
          prefix={ serviceName }
          service={  getImportSourceName(serviceName) }
          askForSource={false}>
        </UserPasswordRegisterView>
      </div>
    ) : <div />;

    return <>
      <Container>
        <Row>
          {button}
          &nbsp;
          {this.props.children}
        </Row>
      </Container>
      {userPasswordView}
      </>
  }
}

export default ImportSources = injectIntl(ImportSources);
