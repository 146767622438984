export const authActions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_FAILED: 'SIGN_IN_FAILED',
  SIGN_IN_FULFILLED: 'SIGN_IN_FULFILLED',

  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',
  SIGN_OUT_FULFILLED: 'SIGN_OUT_FULFILLED',
  SET_DOCTOR: 'SET_DOCTOR',

  setDoctor: (doctor) => ({
    type: authActions.SET_DOCTOR,
    doctor,
  }),

  signInWithProvider: (firebase, authProvider) => ({
    type: authActions.SIGN_IN,
    payload: { firebase, authProvider },
  }),

  signInFailed: (error) => ({
    type: authActions.SIGN_IN_FAILED,
    payload: { error },
  }),

  signInFulfilled: (authUser) => ({
    type: authActions.SIGN_IN_FULFILLED,
    payload: { authUser },
  }),

  signInWithGoogle: (firebase) => authActions.signInWithProvider(
    firebase,
    new firebase.auth.GoogleAuthProvider()
  ),

  signOut: (firebase) => ({
    type: authActions.SIGN_OUT,
    payload: { firebase },
  }),

  signOutFailed: (error) => ({
    type: authActions.SIGN_OUT_FAILED,
    payload: { error },
  }),

  signOutFulfilled: () => ({
    type: authActions.SIGN_OUT_FULFILLED,
  }),
};
