/* global FIREBASE_apiKey, FIREBASE_authDomain, FIREBASE_databaseURL, FIREBASE_storageBucket, FIREBASE_messagingSenderId */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { isLoaded, ReactReduxFirebaseProvider } from 'react-redux-firebase';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from 'utils/history';
// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!../favicons/favicon.ico';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import * as Sentry from "@sentry/react";
//import 'sanitize.css/sanitize.css';

const rrfConfig = { userProfile: 'users' };

const fbConfig = {
    apiKey: FIREBASE_apiKey,
    authDomain: FIREBASE_authDomain,
    databaseURL: FIREBASE_databaseURL,
    storageBucket: FIREBASE_storageBucket,
    messagingSenderId: FIREBASE_messagingSenderId,
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);


// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    // createFirestoreInstance // <- needed if using firestore
};

function AuthIsLoaded({ children }) {
    const auth = useSelector(state => state.firebase.auth);
    if (!isLoaded(auth)) {
        return <div/>;
    }
    return children
}

Sentry.init({
    dsn: "https://3298510a428b97bd542404595948b58a@o4506417899044864.ingest.sentry.io/4506417903828992",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [ "localhost", /^https:\/\/myclinicbot\.com\/api/ ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const render = messages => {
    ReactDOM.render(
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <AuthIsLoaded>
                    <LanguageProvider messages={messages}>
                        <ConnectedRouter history={history}>
                            <App/>
                        </ConnectedRouter>
                    </LanguageProvider>
                </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
        </Provider>,
        MOUNT_NODE,
    );
};

if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept([ './i18n', 'containers/App' ], () => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render(translationMessages);
    });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    new Promise(resolve => {
        resolve(import('intl'));
    })
        .then(() =>
            Promise.all([
                import('intl/locale-data/jsonp/en.js'),
                import('intl/locale-data/jsonp/he.js'),
            ]),
        ) // eslint-disable-line prettier/prettier
        .then(() => render(translationMessages))
        .catch(err => {
            throw err;
        });
} else {
    render(translationMessages);
}
