/**
 *
 * DayTable
 *
 */
import React from 'react';
import { timerActions } from 'components/timer';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import FormattedTimeTd from './formattedTimeTd';
import messages from './messages';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faClipboard,
  faDoorOpen,
  faEraser,
  faPhone,
  faStarOfDavid,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DISABLE_FACEBOOK } from "../../utils/constants";

const Td = styled.td`
    white-space: nowrap;
    text-decoration: ${(props) => (props.canceled ? 'line-through' : null)};
`;

const trBackgroundColor = ({ urgent, selected, userIsHere }) => {
    if (userIsHere) {
        return '#7FDBFF';
    } else if (urgent) {
        return '#ffe6e6';
    } else if (selected) {
        return '#CBE8FE';
    }

    return null;
};

const Tr = styled.tr`
    background-color: ${(props) => trBackgroundColor({ ...props })} !important;
`;

class AppointmentRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.notifyDelay = this.notifyDelay.bind(this);
        this.onMultiPress = this.onMultiPress.bind(this);

        this.handleHoverOn = this.handleHoverOn.bind(this);
        this.handleHoverOff = this.handleHoverOff.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleState = this.handleState.bind(this);
        this.handleUndoCancel = this.handleUndoCancel.bind(this);
        this.handleDeleteAppointment = this.handleDeleteAppointment.bind(this);
        this.handleCallInAppointment = this.handleCallInAppointment.bind(this);
        this.handleAppointmentEnded = this.handleAppointmentEnded.bind(this);
        this.handleAppointmentArrived = this.handleAppointmentArrived.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyDown);
    }

    onMultiPress(appointment) {
        if (!this.props.onMultiPress) {
            return;
        }
        this.props.onMultiPress(appointment, this.props.index);
    }

    notifyDelay() {
        if (!this.props.sendDelay) {
            return;
        }

        this.props.sendDelay(this.props.appointment);
    }

    handleState(shiftKey, insideButton) {
        if (!this.props.onMultiPress) {
            return;
        }

        const multiPress = shiftKey && insideButton;
        const prevMultiPress = this.state.multiPress;

        this.setState({ multiPress, insideButton });

        if (multiPress !== prevMultiPress) {
            const appointment = multiPress ? this.props.appointment : null;
            this.onMultiPress(appointment);
        }
    }

    handleUndoCancel() {
        if (!this.props.onUndoCancel) {
            return;
        }
        this.props.onUndoCancel(this.props.appointment);
    }

    handleCallInAppointment() {
        if (!this.props.onCallInAppointment) {
            return;
        }
        this.props.onCallInAppointment(this.props.appointment);
    }

    handleAppointmentArrived() {
        if (!this.props.onAppointmentArrived) {
            return;
        }
        this.props.onAppointmentArrived(this.props.appointment);
    }

    handleAppointmentEnded() {
        if (!this.props.onAppointmentEnded) {
            return;
        }
        this.props.onAppointmentEnded(this.props.appointment);
    }

    handleDeleteAppointment() {
        if (!this.props.onDeleteAppointment) {
            return;
        }

        this.props.onDeleteAppointment(this.props.appointment);
    }

    handleHoverOff(event) {
        this.handleState(event.shiftKey, false);
    }

    handleHoverOn(event) {
        this.handleState(event.shiftKey, true);
    }

    handleKeyUp(event) {
        if (this.state.insideButton === undefined) {
            return;
        }

        this.handleState(event.shiftKey, this.state.insideButton);
    }

    isKosherNumber(mobileNumber) {
        const prefixes = [ '050-41', '052-71', '052-76', '054-84', '054-85', '053-31', '053-41', '058-32', '055-67' ];

        return prefixes.findIndex(prefix => mobileNumber && mobileNumber.startsWith(prefix)) !== -1;
    }

    handleKeyDown(event) {
        if (this.state.insideButton === undefined) {
            return;
        }

        this.handleState(event.shiftKey, this.state.insideButton);
    }

    render() {
        const { appointment, day, index, currentDelay, intl, selected, displayKupa } = this.props;

        const statusArgs = appointment.statusArgs || {};

        if (statusArgs.at) {
            const at = `${appointment.statusArgs.at}Z`;
            statusArgs.at = timerActions.asLocalTimeIsrael(Date.parse(at)).format('HH:mm');
        }

        let status;
        if (appointment.status && appointment.name) {
            status = (<FormattedMessage {...messages[ appointment.status ]} values={statusArgs}/>);
        }

        let callAppointmentIn;

        const buttonCallIn = () =>
            <OverlayTrigger overlay={<Tooltip id="tooltip-enter">זמן מטופל פנימה</Tooltip>}>
                <Button
                    size="sm"
                    variant="light"
                    onClick={this.handleCallInAppointment}>
                    <FontAwesomeIcon icon={faDoorOpen}/>
                </Button>
            </OverlayTrigger>;

        const buttonUserHere = () =>
            <OverlayTrigger overlay={<Tooltip id="tooltip-enter">מטופל הגיע</Tooltip>}>
                <Button
                    size="sm"
                    variant="light"
                    onClick={this.handleAppointmentArrived}>
                    <FontAwesomeIcon icon={faClipboard}/>
                </Button>
            </OverlayTrigger>;

        const buttonAppointmentEnded = () =>
            <OverlayTrigger overlay={<Tooltip id="tooltip-enter">סיום פגישה</Tooltip>}>
                <Button
                    size="sm"
                    variant="light"
                    onClick={this.handleAppointmentEnded}>
                    <FontAwesomeIcon icon={faCheckSquare}/>
                </Button>
            </OverlayTrigger>;

        let userIsHere = false;
        if (appointment.status === 'userAtPlace') {
            callAppointmentIn = buttonCallIn();
            userIsHere = true;
        } else if (appointment.status === 'sentImHereEnter') {
            callAppointmentIn = <>
                {buttonAppointmentEnded()}&nbsp;
                {buttonCallIn()}
            </>
        } else if (appointment.status === 'sentImHereReminder') {
            callAppointmentIn = buttonUserHere();
        }

        const mobileNumberStyle = {};

        if (appointment.is_mobile_number === false) {
            mobileNumberStyle.color = 'red';
        }

        const time = timerActions.parseTime(appointment.time).toDate();

        const rowStyle = {};

        const timeAndDay = timerActions.asLocalTimeIsrael(new Date(day.getFullYear(), day.getMonth(), day.getDate(), time.getHours(), time.getMinutes())).toDate();

        const secondsUntil = (timeAndDay.getTime() - timerActions.currentTimeInIsrael().toDate().getTime()) / 1000;

        const setPassed = () => this.setState({ passed: true });

        if (secondsUntil < 0) {
            this.state.passed = true;
        } else {
            setTimeout(setPassed, (secondsUntil + 10) * 1000);
        }

        if (this.state.passed === true) {
            rowStyle.background = '#ddd';
        }

        const commentsStyle = {
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whitespace: 'nowrap',
        };

        const canceled = appointment.status === 'canceledBySms';

        const rowSelected = selected && appointment.name && !canceled;

        let icon;
        if (appointment.phone_only) {
            icon = <FontAwesomeIcon icon={faPhone}/>
        } else if (appointment.fb_user && !DISABLE_FACEBOOK) {
            icon = <FontAwesomeIcon icon={faFacebookSquare}/>
        }

        let deleteButton;

        if (appointment.urgent && !appointment.mobile_number) {
            deleteButton = (
                <Button
                    variant="light"
                    size="sm"
                    onClick={this.handleDeleteAppointment}
                >
                    <FontAwesomeIcon icon={faTrash}/>
                </Button>
            );
        }

        let cancelButton;
        if (canceled && this.props.onUndoCancel) {
            cancelButton = (
                <Button
                    size="sm"
                    variant="light"
                    onClick={this.handleUndoCancel}
                >
                    <FontAwesomeIcon icon={faEraser}/>
                </Button>
            );
        }

        const kupaTd = displayKupa ? <Td canceled={canceled}>{appointment.kupa}</Td> : null;

        const kosherNumber = this.isKosherNumber(appointment.mobile_number);

        const kosherIcon = kosherNumber && <>&nbsp;<FontAwesomeIcon icon={faStarOfDavid}/></>;

        return (
            <Tr userIsHere={userIsHere} selected={rowSelected} urgent={appointment.urgent} onKeyUp={this.handleKeyUp}
                onKeyDown={this.handleKeyDown} onMouseEnter={this.handleHoverOn} onMouseLeave={this.handleHoverOff}>
                <th style={rowStyle} scope="row">{index + 1}&nbsp;{deleteButton}{cancelButton}</th>
                <Td canceled={canceled}>{callAppointmentIn}&nbsp;{icon} {appointment.name}</Td>
                <FormattedTimeTd
                    currentDelay={currentDelay}
                    notifyDelay={this.notifyDelay}
                    intl={intl}
                    selected={selected}
                    appointment={appointment}
                    canceled={canceled}
                />
                {kupaTd}
                <Td canceled={canceled}>{appointment.id}</Td>
                <Td canceled={canceled} style={mobileNumberStyle}>{appointment.mobile_number}{kosherIcon}</Td>
                <Td canceled={canceled}>{appointment.mobile_number !== appointment.home_number ? appointment.home_number : ''}</Td>
                <Td canceled={canceled} style={commentsStyle} title={appointment.comment}>{appointment.comment}</Td>
                <Td>{status}</Td>
            </Tr>
        );
    }
}

AppointmentRow.propTypes = {
    appointment: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    day: PropTypes.object,
    currentDelay: PropTypes.number,
    sendDelay: PropTypes.func,
    onMultiPress: PropTypes.func,
    onDeleteAppointment: PropTypes.func,
    onCallInAppointment: PropTypes.func,
    onAppointmentArrived: PropTypes.func,
    onAppointmentEnded: PropTypes.func,
    onUndoCancel: PropTypes.func,
    intl: PropTypes.object,
    selected: PropTypes.bool,
    displayKupa: PropTypes.bool
};

export default injectIntl(AppointmentRow);
