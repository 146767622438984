/**
 *
 * DayTable
 *
 */
/* global API_URL */

import React from 'react';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { faChevronUp, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Table } from "react-bootstrap";
import classNames from 'classnames/bind';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import UrgentPatientsRow from './urgentPatientsRow';
import NewPatientDialog from './newPatientDialog';
import messages from './messages';
import { createSelector } from "reselect";

import { timerActions } from '../timer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDoctorDayData, getUrgentPatients } from "../DayTable/util";

const formatDay = (day) => timerActions.asLocalTimeIsrael(day).format('YYYY/M/D');

const getDayListPath = (doctor, day, prop) => {
    const date = formatDay(day);

    return `/days/${doctor.id}/${date}/${prop}`;
};

const getUrgentPatientsPath = (doctor) => `/urgentPatients/${doctor.id}`;

const UrgentPatientsRowEmpty = () => <tr>
    <td colSpan="8"><FormattedMessage {...messages.empty} /></td>
</tr>;

const selectFirebase = () => state => state.firebase;
const selectDoctor = () => (state) => state.auth.doctor;
const selectDay = () => (state, props) => props.day;

const mapStateToProps = createSelector(
    selectFirebase(),
    selectDoctor(),
    selectDay(),
    (firebase, doctor, day) => ({
        doctor,
        urgentPatients: getUrgentPatients(firebase, doctor),
        lastSentUrgentAppointments: getDoctorDayData(firebase, doctor, day).last_sent_urgent_appointments,
        lastCreatedUrgentAppointments: getDoctorDayData(firebase, doctor, day).last_created_urgent_appointments,
    })
);

@connect(mapStateToProps)
@firebaseConnect((props) => ([
    getUrgentPatientsPath(props.doctor),
    getDayListPath(props.doctor, props.day, 'last_sent_urgent_appointments'),
    getDayListPath(props.doctor, props.day, 'last_created_urgent_appointments') ]))
class UrgentPatients extends React.Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);

        this.handlePatientAdded = this.handlePatientAdded.bind(this);
        this.ajaxRequest = this.ajaxRequest.bind(this);
        this.handleDeleteRowWrap = this.handleDeleteRowWrap.bind(this);
        this.handleDisableWrap = this.handleDisableWrap.bind(this);

        this.state = {};
    }

    sendPatientsMessages = async () => {
        const { firebase, doctor } = this.props;

        const accessToken = await firebase.auth().currentUser.getIdToken();

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}/${doctor.id}`,
        };

        await this.ajaxRequest(
            fetch(`${API_URL}/sendUrgentPatient`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    day: this.props.day,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }

                    return response;
                })
                .then((response) => {
                    if (!response.ok) {
                        alert(response.message || response.statusText);
                    }

                    return response;
                })
                .then(() => {
                    this.setState({ updatingRows: false });
                }),
        );
    };

    handleDisableWrap(rowIdx) {
        return async (disable) => {
            const { firebase, doctor } = this.props;

            const accessToken = await firebase.auth().currentUser.getIdToken();
            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}/${doctor.id}`,
            };

            await this.ajaxRequest(
                fetch(`${API_URL}/disableUrgentPatient`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({
                        index: rowIdx,
                        disable,
                    }),
                })
            ).then(() => {
                this.setState({ updatingRows: false });
            });
        };
    }

    handleDeleteRowWrap(rowIdx) {
        return async () => {
            const { firebase, doctor } = this.props;

            const accessToken = await firebase.auth().currentUser.getIdToken();

            const headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}/${doctor.id}`,
            };

            await this.ajaxRequest(
                fetch(`${API_URL}/deleteUrgentPatient`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({
                        index: rowIdx,
                    }),
                })
            ).then(() => {
                this.setState({ updatingRows: false });
            });
        };
    }

    ajaxRequest(p) {
        this.setState({ sending: true });

        return p
            .then(() => {
                this.setState({ sending: false });
            });
    }

    openNewPatientDialog = () => this.setState({ isShowingModal: true });
    closeNewPatientDialog = () => this.setState({ isShowingModal: false });

    async handlePatientAdded(patient) {
        const newPatient = {
            name: patient.name,
            mobile_number: patient.phone,
            comments: patient.comments,
            id: patient.id,
        };

        this.closeNewPatientDialog();

        const { firebase, doctor } = this.props;

        const accessToken = await firebase.auth().currentUser.getIdToken();

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}/${doctor.id}`,
        };

        await this.ajaxRequest(
            fetch(`${API_URL}/addUrgentPatient`, {
                method: 'POST',
                headers,
                body: JSON.stringify({
                    record: newPatient,
                }),
            })
        );
    }

    render() {
        const { urgentPatients, lastSentUrgentAppointments, lastCreatedUrgentAppointments } = this.props;
        const { isShowingModal } = this.state;

        const urgentPatientList = (urgentPatients || []).length > 0 ?
            _.map(urgentPatients, (urgentPatient, index) => (
                <UrgentPatientsRow key={urgentPatient.mobile_number} onDelete={this.handleDeleteRowWrap(index)}
                                   onDisable={this.handleDisableWrap(index)} index={index}
                                   urgentPatient={urgentPatient}/>)) :
            <UrgentPatientsRowEmpty/>;

        const sendButtonClasses = classNames('btn btn-default', {
            disabled: lastCreatedUrgentAppointments === null || (lastSentUrgentAppointments !== null && lastSentUrgentAppointments > lastCreatedUrgentAppointments),
        });

        const addButton = (
            <Button onClick={this.openNewPatientDialog}>
                <FontAwesomeIcon icon={faPlus}/>&nbsp;
                <FormattedMessage {...messages.add} />
            </Button>);

        const scrollUpButton = (
            <Button onClick={Scroll.animateScroll.scrollToTop}>
                <FontAwesomeIcon icon={faChevronUp}/>&nbsp;
            </Button>);

        const sendButton = (
            <Button className={sendButtonClasses} onClick={this.sendPatientsMessages}>
                <FontAwesomeIcon icon={faPaperPlane}/>&nbsp;
                <FormattedMessage {...messages.send} />
            </Button>);

        const modalDialog = isShowingModal &&
            <NewPatientDialog onSave={this.handlePatientAdded} onClose={this.closeNewPatientDialog}/>;

        return (
            <div>
                <div style={{ marginBottom: '10px' }}>
                    {addButton} {sendButton} {scrollUpButton}
                </div>
                <Table striped bordered hover size="sm">
                    <thead className="thead-default">
                    <tr>
                        <th>#</th>
                        <th/>
                        <th>שם</th>
                        <th>ת.ז</th>
                        <th>נייד</th>
                        <th>זמן</th>
                        <th>הערות</th>
                        <th>-</th>
                    </tr>
                    </thead>
                    <tbody>
                    {urgentPatientList}
                    </tbody>
                </Table>
                {modalDialog}
            </div>
        );
    }
}

UrgentPatients.propTypes = {
    urgentPatients: PropTypes.array,
    lastCreatedUrgentAppointments: PropTypes.object,
    lastSentUrgentAppointments: PropTypes.object,
    day: PropTypes.object.isRequired,
};

// noinspection JSUnusedGlobalSymbols
export default injectIntl(UrgentPatients);
