/* global APP_TITLE */
import React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect, getVal } from 'react-redux-firebase';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Navbar } from "react-bootstrap";
import { withRouter } from "react-router";
import { authActions } from '../auth';
import messages from './messages';

const selectAuth = () => state => state.firebase.auth;
const selectFirebase = () => state => state.firebase;
const selectDoctor = () => (state) => state.auth.doctor;

const mapStateToProps = createSelector(
    selectFirebase(),
    selectAuth(),
    selectDoctor(),
    (firebase, auth, doctor) => ({
        auth,
        connected: !!(firebase.data[ '' ] && firebase.data[ '' ].info && firebase.data[ '' ].info.connected),
        doctor,
    })
);

@connect(mapStateToProps, { signInWithGoogle: authActions.signInWithGoogle, signOut: authActions.signOut })
@firebaseConnect(() => [ '.info/connected', 'server_time' ])
class Header extends React.Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);
        this.state = { connected: true };
        this.checkIfStillDisconnected = this.checkIfStillDisconnected.bind(this);
    }

    checkIfStillDisconnected() {
        const { connected } = this.props;

        this.setState({ connected });
    }

    logoutWithGoogle() {
        const { firebase, history } = this.props;
        firebase.auth().signOut();
        history.push("/");
    }

    render() {
        const { auth, connected, doctor } = this.props;

        let loginOrLogout;

        if (auth) {
            loginOrLogout = (
                <div>
                    <img src={auth.photoURL} style={{ marginLeft: 10, borderRadius: '50%' }} width="35" alt="profile"/>
                    <ButtonGroup className="mr-2">
                        <Button onClick={() => this.logoutWithGoogle()}>יציאה</Button>
                    </ButtonGroup>
                    <ButtonGroup className="mr-2">
                        <Button onClick={() => window.location.reload(true)}>רענן גרסה</Button>
                    </ButtonGroup>
                </div>
            );
        } else {
            loginOrLogout = (
                <button type="button" className="btn btn-default navbar-btn"
                        onClick={() => this.props.signInWithGoogle(this.props.firebase)}>כניסה</button>);
        }

        let warn;
        if (connected !== this.state.connected) {
            setTimeout(this.checkIfStillDisconnected, 3000);
        }

        if (!this.state.connected) {
            warn = (
                <div className="alert alert-warning">
                    <center><strong><FormattedMessage {...messages.noConnection} /></strong></center>
                </div>);
        }

        return (
            <div>
                {warn}
                <Navbar bg="light" expand="lg">
                    <Container fluid>
                        {loginOrLogout}
                        <div className="navbar-header pull-right">
                            <Link className="navbar-brand" to={`/app/${doctor.id}`}>{doctor.name}</Link>
                            <Navbar.Brand>
                                <Link className="navbar-brand" to={`/app/${doctor.id}`}>{APP_TITLE}</Link>
                            </Navbar.Brand>
                        </div>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

Header.propTypes = {
    auth: PropTypes.object,
    doctor: PropTypes.object,
    firebase: PropTypes.object,
    connected: PropTypes.bool,
    signInWithGoogle: PropTypes.func,
    signOut: PropTypes.func,
};

export default withRouter(Header);