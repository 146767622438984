import React from 'react';
import Helmet from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../../containers/HomePage';
import MainPage from '../../containers/MainPage';
import VerifyPage from '../../containers/VerifyPage';
import WelcomePage from '../../containers/WelcomePage'
import SignupPage from '../../containers/SignupPage'
import MessagesPage from '../../containers/MessagesPage';
import useInjectSaga from '../../utils/injectSaga';
import saga from './sagas';

const key = 'App';

function App() {
    useInjectSaga({ key, saga });
    return (
        <div>
            <Helmet
                titleTemplate="%s - MyClinicBot"
                defaultTitle="MyClinicBot"
                meta={[
                    { name: 'description', content: 'MyClinicBot' },
                ]}
                link={[
                    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favs/apple-touch-icon.png' },
                    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favs/favicon-32x32.png' },
                    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favs/favicon-32x32.png' },
                ]}
            >
            </Helmet>
            <Switch>
                <Route exact path="/" component={MainPage}/>
                <Route exact path="/app/:doctorId" component={HomePage}/>
                <Route exact path="/app/:doctorId/messages" component={MessagesPage}/>
                <Route exact path="/verify" component={VerifyPage}/>
                <Route exact path="/welcome" component={WelcomePage}/>
                <Route exact path="/signup" component={SignupPage}/>
            </Switch>
        </div>
    );
}

export default App;
