/*
 * DayTable Messages
 *
 * This contains all the text for the DayTable component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  askForSource: {
    id: 'app.components.Settings.UserPasswordRegisterView.askForSource',
    defaultMessage: 'משתמש ב{service}?',
  },
  username: {
    id: 'app.components.Settings.UserPasswordRegisterView.username',
    defaultMessage: 'שם משתמש',
  },
  password: {
    id: 'app.components.Settings.UserPasswordRegisterView.password',
    defaultMessage: 'סיסמה',
  },
  address: {
    id: 'app.components.Settings.UserPasswordRegisterView.address',
    defaultMessage: 'כתובת',
  },
  pleaseSelect: {
    id: 'app.components.Settings.UserPasswordRegisterView.pleaseSelect',
    defaultMessage: 'נא לבחור',
  }
});
