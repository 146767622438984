/**
 *
 * DayTable
 *
 */
/* global API_URL */

import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { firebaseConnect } from "react-redux-firebase";
import messages from "./messages";

const isTimeStrValid = text => /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(text);

const selectDoctor = () => state => state.auth.doctor;

const mapStateToProps = createSelector(
  selectDoctor(),
  doctor => ({
    doctor
  })
);

@connect(mapStateToProps)
@firebaseConnect()
class UrgentAppointment extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);

    this.addUrgentAppointment = this.addUrgentAppointment.bind(this);
    this.ajaxRequest = this.ajaxRequest.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {};
  }

  handleChange(event) {
    const text = (event.target.value || "").trim();
    const validTime = isTimeStrValid(text);
    this.setState({ timeValue: text, validTime });
  }

  ajaxRequest(p) {
    this.setState({ sending: true });

    return p.finally(() => {
      this.setState({ sending: false });
    });
  }

  async addUrgentAppointment() {
    const { doctor, firebase } = this.props;
    const accessToken = await firebase.auth().currentUser.getIdToken();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}/${doctor.id}`
    };

    await this.ajaxRequest(
      fetch(`${API_URL}/addUrgentAppointment`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          day: this.props.day.toISOString(),
          time: this.state.timeValue
        })
      })
        .then(response => response.json())
        .then(result => {
          if (!result.ok) {
            alert(result.message);
          }
        })
    );
  }

  render() {
    return (
      <Form inline>
        <Form.Group>
          <Form.Label className="sr-only" htmlFor="hour">
            שעה
          </Form.Label>
          <Form.Control
            type="text"
            size="5"
            name="hour"
            value={this.state.timeValue}
            onChange={this.handleChange}
            id="hour"
            placeholder="18:00"
          />
          <Button
            disabled={this.state.sending || !this.state.validTime}
            onClick={this.addUrgentAppointment}
          >
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;
            <FormattedMessage {...messages.add} />
          </Button>
        </Form.Group>
      </Form>
    );
  }
}

UrgentAppointment.propTypes = {
  day: PropTypes.object.isRequired
};

// noinspection JSUnusedGlobalSymbols
export default UrgentAppointment;
