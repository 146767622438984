import React from 'react';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import _ from "lodash";
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { createSelector } from "reselect";
import { timerActions } from '../../components/timer';
import PatientsPage from '../../containers/PatientsPage';
import Header from '../../components/Header';
import { authActions } from '../../components/auth';


const selectFirebase = () => state => state.firebase;

const selectDoctorId = () => (state, props) => {
  const { match } = props;
  const { doctorId } = match.params;

  return doctorId;
};

const getDoctorPath = (doctorId) => `doctors/${doctorId}`;

const selectDay = () => (state, props) => {
  const qsParams = props.location.search ? qs.parse(props.location.search) : null;

  let actualDay;
  if (qsParams && qsParams.day) {
    actualDay = timerActions.parseDayMonth(qsParams.day).toDate();
  } else {
    actualDay = timerActions.currentTimeInIsrael().toDate();
  }

  return actualDay;
};

const mapStateToProps = createSelector(
  selectFirebase(),
  selectDoctorId(),
  selectDay(),
  (firebase, doctorId, day) => ({
    doctorId,
    doctor: firebase.data.doctors && firebase.data.doctors[doctorId],
    day,
  })
);

@connect()
class HomePageWithDoctor extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    const { dispatch, doctor } = this.props;
    dispatch(authActions.setDoctor(doctor));
    this.setState({mounted: true})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { dispatch, doctor } = this.props;
    if (!_.isEqual(prevProps.doctor, doctor)) {
      dispatch(authActions.setDoctor(doctor));
    }
  }

  render() {
    if (!this.state.mounted) {
      return (<div />)
    }

    const { day } = this.props;

    return (
      <div>
        <Header day={day} />
        <PatientsPage day={day} />
      </div>
    );
  }
}

@connect(mapStateToProps)
@firebaseConnect((props) => [getDoctorPath(props.doctorId)])
export default class HomePage extends React.Component { // eslint-disable-line react/prefer-stateless-function
  render() {
    const { doctor, day } = this.props;

    if (isLoaded(doctor)) {
      return (<HomePageWithDoctor doctor={doctor} day={day} />)
    }

    return (<div />);
  }
}

HomePage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};
