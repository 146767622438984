import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import authReducer from 'components/auth/reducer';
import currentDayReducer from 'components/timer/reducer';
import { firebaseReducer } from 'react-redux-firebase'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    global: globalReducer,
    language: languageProviderReducer,
    router: connectRouter(history),
    firebase: firebaseReducer,
    currentDay: currentDayReducer,
    auth: authReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
