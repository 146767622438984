import React, { Component } from "react";
import { withRouter } from "react-router";
import SignUpView from "./SignUpView";
import { authActions } from 'components/auth';
import { firebaseConnect } from "react-redux-firebase";
import connect from "react-redux/es/connect/connect";

@connect(() => {}, { signInWithGoogle: authActions.signInWithGoogle, signOut: authActions.signOut })
@firebaseConnect(() => [])
class SignupPage extends Component {
  constructor(props) {
    super(props);
  }

  handleSignUpEmailPassword = async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      const user = await this.props.auth.createUserWithEmailAndPassword(email.value, password.value);
      this.props.history.push("/");
    } catch (error) {
      alert(error);
    }
  };

  handleSignupWithGoogle = () => {
    this.props.signInWithGoogle(this.props.firebase);
  };

  render() {
    return <SignUpView
      style={{dir: "rtl"}}
      onSubmitUserPassword={this.props.handleSignUpEmailPassword}
      onGoogleSignup={this.handleSignupWithGoogle} />;
  }
}

export default withRouter(SignupPage);
