/* global API_URL */
import classNames from 'classnames/bind';
import React from 'react';
import Phone from 'react-phone-number-input';
import * as qs from 'query-string';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';

export default class VerifyPage extends React.Component { // eslint-disable-line react/prefer-stateless-function
  // noinspection JSMethodCanBeStatic
  constructor(props) {
    super(props);
    this.updateCurrentPhone = this.updateCurrentPhone.bind(this);
    this.validateNumber = this.validateNumber.bind(this);
    this.ajaxRequest = this.ajaxRequest.bind(this);
    this.updateCurrentSmsCode = this.updateCurrentSmsCode.bind(this);

    const { redirect_uri } = qs.parse(this.props.location.search);
    this.state = { redirect_uri };
  }

  componentWillMount() {
    const extensionsLoader = (d, s, id) => {
      if (d.getElementById(id)) {
        return;
      }

      const fjs = d.getElementsByTagName(s)[0];
      const js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.com/en_US/messenger.Extensions.js';
      fjs.parentNode.insertBefore(js, fjs);
    };

    extensionsLoader(document, 'script', 'Messenger');
  }

  updateCurrentSmsCode(event) {
    this.state.smsCode = event.target.value;

    this.ajaxRequest(
      fetch(`${API_URL}/verify_code`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: this.state.phone,
          code: this.state.smsCode,
        }),
      }).then((response) => response.json()).then((result) => {
        if (result.expired) {
          this.setState({ enterCode: false, phone: '' });
        } else {
          this.setState({ codeValidated: result.ok });
          if (result.ok && this.state.redirect_uri) {
            const authorizationCode = encodeURIComponent(result.authorization_code);
            window.location.href = `${this.state.redirect_uri}&authorization_code=${authorizationCode}`;
          }
        }
      }));
  }

  updateCurrentPhone(phone) {
    this.state.phone = phone;
    this.setState({
      validPhoneNumber: phone && phone.length === 13,
    });
  }

  ajaxRequest(p) {
    this.setState({ sending: true });

    return p
      .then(() => {
        this.setState({ sending: false });
      });
  }

  validateNumber() {
    this.ajaxRequest(
      fetch(`${API_URL}/verify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phone: this.state.phone,
        }),
      })
    ).then(() => {
      this.setState({ enterCode: true, smsCode: '' });
    });
  }

  render() {
    let inputControls;
    if (this.state.enterCode) {
      inputControls = (
        <div className="row">
          <h1 className="text-center">קוד סמס</h1>

          <div className="col-md-4 col-md-offset-4" style={{ marginTop: '1em' }}>
            <input
              type="number"
              dir="ltr"
              pattern="[0-9]*"
              inputMode="numeric"
              className="input-lg form-control"
              placeholder={'הכנס קוד'}
              value={this.state.smsCode}
              onChange={this.updateCurrentSmsCode}
            />
            <small>נשלח קוד למספר טלפון, הכנס אותו על מנת שהבוט יזהה אותך</small>
          </div>
        </div>);
    } else {
      const buttonClasses = classNames('btn btn-lg btn-block btn-primary', {
        disabled: !this.state.validPhoneNumber,
      });

      inputControls = (
        <div className="row">
          <h1 className="text-center">מה מספר הטלפון שלך?</h1>
          <div className="col-md-4 col-md-offset-4" style={{ marginTop: '1em' }}>
            <Phone
              autoFocus
              dir="ltr"
              inputClassName="input-lg form-control"
              countrySelectToggleClassName="hidden"
              country="IL"
              countries={['IL']}
              placeholder="מספר טלפון"
              value={this.state.phone}
              smartCaret={false}
              onChange={this.updateCurrentPhone}
            />
          </div>
          <div className="col-md-4 col-md-offset-4" style={{ marginTop: '1em' }}>
            <button type="button" className={buttonClasses} onClick={this.validateNumber}>שלח קוד אישור</button>
            <small>לחיצה על הכפתור תשלח קוד למספר טלפון, יש להכניס את הקוד במסך הבא</small>
          </div>
        </div>);
    }

    return (
      <div className="container">
        {inputControls}
      </div>
    );
  }
}

VerifyPage.propTypes = {
  location: PropTypes.object,
};
