/**
 *
 * DayTable
 *
 */
/* global API_URL */

import React from 'react';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import Sticky from 'react-stickynode';
import Scroll from 'react-scroll';
import { createSelector } from "reselect";
import { Button, ButtonGroup, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { getDoctorDayData } from "./util";
import UrgentAppointment from '../../components/UrgentAppointment';
import UrgentPatients from '../../components/UrgentPatients';
import { timerActions } from '../timer';
import ImportSources from '../../components/UploadControl/importSources';
import messages from './messages';
import DelaySelect from './delaySelect';
import AppointmentRow from './appointmentRow';


const selectFirebase = () => state => state.firebase;
const selectDoctor = () => (state) => state.auth.doctor;
const selectDay = () => (state, props) => props.day;

const mapStateToProps = createSelector(
    selectFirebase(),
    selectDoctor(),
    selectDay(),
    (firebase, doctor, day) => ({
        currentDelay: getDoctorDayData(firebase, doctor, day).current_delay,
        syncSource: getDoctorDayData(firebase, doctor, day).sync_source,
        appointments: getDoctorDayData(firebase, doctor, day).appointments,
        canceledAppointments: getDoctorDayData(firebase, doctor, day).canceled_appointments,
        doctor
    })
);

const DayTableAppointments = ({
                                  day,
                                  appointments,
                                  currentDelay,
                                  sendDelay,
                                  onMultiPress,
                                  multiSelect,
                                  onDeleteAppointment,
                                  onUndoCancel,
                                  displayKupa,
                                  onCallInAppointment,
                                  onAppointmentEnded,
                                  onAppointmentArrived
                              }) => {
    const appointmentsList = _.map(appointments, (appointment, index) => {
        const selected = multiSelect && index >= multiSelect.start && index <= multiSelect.end;
        return (
            <AppointmentRow
                selected={selected}
                displayKupa={displayKupa}
                onDeleteAppointment={onDeleteAppointment}
                onUndoCancel={onUndoCancel}
                onCallInAppointment={onCallInAppointment}
                onAppointmentEnded={onAppointmentEnded}
                onMultiPress={onMultiPress}
                onAppointmentArrived={onAppointmentArrived}
                sendDelay={sendDelay}
                currentDelay={currentDelay}
                day={day}
                key={index}
                index={index}
                appointment={appointment}
            />
        );
    });

    const kupaTh = displayKupa ? <th>קופה</th> : null;

    return (
        <Table striped bordered responsive hover size="sm">
            <thead className="thead-default">
            <tr>
                <th>#</th>
                <th>שם</th>
                <th>שעה</th>
                {kupaTh}
                <th>ת.ז</th>
                <th>נייד</th>
                <th>בית</th>
                <th>הערות</th>
                <th>-</th>
            </tr>
            </thead>
            <tbody>
            {appointmentsList}
            </tbody>
        </Table>
    );
};

DayTableAppointments.propTypes = {
    appointments: PropTypes.array.isRequired,
    day: PropTypes.object.isRequired,
    currentDelay: PropTypes.number,
    sendDelay: PropTypes.func,
    onMultiPress: PropTypes.func,
    onDeleteAppointment: PropTypes.func,
    onUndoCancel: PropTypes.func,
    multiSelect: PropTypes.object,
};


const formatDay = (day) => timerActions.asLocalTimeIsrael(day).format('YYYY/M/D');

const getDayListPath = (doctor, day) => {
    const date = formatDay(day);

    return `/days/${doctor.id}/${date}/appointments`;
};

const getCanceledAppointmentListPath = (doctor, day) => {
    const date = formatDay(day);

    return `/days/${doctor.id}/${date}/canceled_appointments`;
};

const getSyncSourcePath = (doctor, day) => {
    const date = formatDay(day);

    return `/days/${doctor.id}/${date}/sync_source`;
};

const getCurrentDelayPath = (doctor, day) => {
    const date = formatDay(day);

    return `/days/${doctor.id}/${date}/currentDelay`;
};

@connect(mapStateToProps)
@firebaseConnect()
class ToolbarWithData extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentDelay: this.props.currentDelay };

        this.sendAppointments = this.sendAppointments.bind(this);
        this.updateCurrentDelay = this.updateCurrentDelay.bind(this);
    }

    sendAppointments() {
        this.props.onSendAppointments();
    }

    updateCurrentDelay(currentDelay) {
        const { prevDelay } = this.state;

        this.setState({ currentDelay });

        this.props.onDelayUpdated(prevDelay, currentDelay);
    }

    render() {
        const { disabled, day, currentDelay, hasAppointments, syncSource } = this.props;

        // noinspection ConstantConditionalExpressionJS
        const scrollDownButton = !hasAppointments ? null : (
            <Button disabled={disabled} onClick={Scroll.animateScroll.scrollToBottom}>
                <FontAwesomeIcon icon={faChevronDown}/>
            </Button>);

        const sendButton = timerActions.isToday(day) && hasAppointments ?
            (<Button disabled={disabled} onClick={this.sendAppointments} className="mr-2">
                <FontAwesomeIcon icon={faPaperPlane}/>&nbsp;
                <FormattedMessage {...messages.sendList} />
            </Button>) : null;

        const addUrgentAppointment = timerActions.isToday(day) || !hasAppointments ? <><UrgentAppointment
            className="mr-2" day={day}/>&nbsp;</> : null;

        const delaySelect = timerActions.isToday(day) && hasAppointments ?
            <DelaySelect currentDelay={currentDelay} disabled={disabled} onChange={this.updateCurrentDelay}/>
            : null;

        return (
            <div>
                <div className="mb-2">
                    <ButtonGroup>
                        <div className="mr-2">
                            <ImportSources disabled={disabled} day={day} syncSource={syncSource}>
                                {sendButton}
                                {addUrgentAppointment}
                                {scrollDownButton}
                            </ImportSources>
                        </div>
                    </ButtonGroup>
                </div>
                {delaySelect}
            </div>
        )
    }
}

@connect(mapStateToProps)
@firebaseConnect((props) => ([
    getDayListPath(props.doctor, props.day),
    getSyncSourcePath(props.doctor, props.day),
    getCanceledAppointmentListPath(props.doctor, props.day),
    getCurrentDelayPath(props.doctor, props.day)
]))
class DayTable extends React.Component { // eslint-disable-line react/prefer-stateless-function
    constructor(props) {
        super(props);

        this.state = { currentDelay: this.props.currentDelay };

        this.ajaxRequest = this.ajaxRequest.bind(this);
        this.sendDelay = this.sendDelay.bind(this);
        this.onMultiPress = this.onMultiPress.bind(this);
        this.onDeleteAppointment = this.onDeleteAppointment.bind(this);
        this.onUndoCancel = this.onUndoCancel.bind(this);
        this.openModalWithError = this.openModalWithError.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleModalCloseRequest = this.handleModalCloseRequest.bind(this);
        this.onDelayUpdated = this.onDelayUpdated.bind(this);
        this.onSendAppointments = this.onSendAppointments.bind(this);
        this.onCallInAppointment = this.onCallInAppointment.bind(this);
        this.onAppointmentEnded = this.onAppointmentEnded.bind(this);
        this.onAppointmentArrived = this.onAppointmentArrived.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ currentDelay: nextProps.currentDelay });
    }

    onSendAppointments() {
        return this.ajaxRequest(
            `${API_URL}/send_today_sms`, {
                method: 'POST',
                body: JSON.stringify({
                    day: this.props.day.toISOString(),
                }),
            }
        );
    }

    onDelayUpdated(prevDelay, currentDelay) {
        return this.ajaxRequest(
            `${API_URL}/set_current_delay`, {
                method: 'POST',
                body: JSON.stringify({
                    currentDelay,
                }),
            }
        ).catch(() => {
            this.setState({ prevDelay });
        });
    }

    onDeleteAppointment(appointment) {
        const time = appointment.time;

        return this.ajaxRequest(
            `${API_URL}/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    time,
                }),
            }
        );
    }

    onAppointmentArrived(appointment) {
        const time = appointment.time;

        return this.ajaxRequest(
            `${API_URL}/appointment_arrived`, {
                method: 'POST',
                body: JSON.stringify({
                    time,
                }),
            }
        );
    }

    onAppointmentEnded(appointment) {
        const time = appointment.time;

        return this.ajaxRequest(
            `${API_URL}/appointment_ended`, {
                method: 'POST',
                body: JSON.stringify({
                    time,
                }),
            }
        );
    }

    onCallInAppointment(appointment) {
        const time = appointment.time;

        return this.ajaxRequest(
            `${API_URL}/call_in_appointment`, {
                method: 'POST',
                body: JSON.stringify({
                    time,
                }),
            }
        );
    }

    async onUndoCancel(appointment) {
        const time = appointment.time;

        await this.ajaxRequest(
            `${API_URL}/undo_cancel`, {
                method: 'POST',
                body: JSON.stringify({
                    time,
                }),
            }
        );
    }

    onMultiPress(appointment, index) {
        if (appointment === null) {
            this.setState({ multiSelect: null });
            return;
        }

        let markerIndex = -1;
        for (let i = index - 1; i >= 0; i -= 1) {
            const current = this.props.appointments[ i ];

            const markerFound = current.sendDelay === this.state.currentDelay;
            if (markerFound) {
                markerIndex = i + 1;
                break;
            }
        }

        if (markerIndex < 0) {
            return;
        }

        this.setState({ multiSelect: { start: markerIndex, end: index } });
    }

    openModalWithError(e) {
        let modelError = e;

        if (modelError.message) {
            modelError = modelError.message;
        } else if (modelError.statusText) {
            modelError = modelError.statusText;
        }

        this.setState({ modelError, modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modelError: null, modalIsOpen: false });
    }

    handleModalCloseRequest() {
        this.setState({ modelError: null, modalIsOpen: false });
    }

    async sendDelay(appointment) {
        const times = [ appointment.time ];

        const { multiSelect } = this.state;

        if (multiSelect) {
            for (let i = multiSelect.start; i <= multiSelect.end; i += 1) {
                times.push(this.props.appointments[ i ].time);
            }
        }

        await this.ajaxRequest(
            `${API_URL}/notify_delay`, {
                method: 'POST',
                body: JSON.stringify({
                    times,
                    currentDelay: this.state.currentDelay,
                }),
            }
        );
    }

    handleErrors(response) {
        if (!response.ok) {
            throw Error(response);
        }

        return response;
    }

    async ajaxRequest(url, options) {
        const { firebase, doctor } = this.props;

        const accessToken = await firebase.auth().currentUser.getIdToken();
        options.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}/${doctor.id}`,
        };

        const p = fetch(url, options);

        this.setState({ sending: true });

        return p
            .then(this.handleErrors)
            .catch(this.openModalWithError)
            .then(() => {
                this.setState({ sending: false });
            });
    }

    renderModel() {
        return (
            <Modal
                className="Modal__Bootstrap modal-dialog"
                contentLabel="ERROR"
                closeTimeoutMS={150}
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.handleModalCloseRequest}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={this.handleModalCloseRequest}>
                            <span>&times;</span>
                            <span className="sr-only">סגור</span>
                        </button>
                        <h4 className="modal-title">תקלה</h4>
                    </div>
                    <div className="modal-body">
                        <p>{this.state.modelError}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default" onClick={this.handleModalCloseRequest}>סגור
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const { appointments, canceledAppointments, day, syncSource, doctor } = this.props;
        const { currentDelay } = this.state;

        const shouldDisabled = this.state.uploading || this.state.sending;

        let element;
        if (!isLoaded(appointments)) {
            element = (<span>טוען</span>);
        } else {
            const hasAppointments = (appointments || []).length > 0;

            if (!hasAppointments) {
                element = (
                    <div>
                        <ToolbarWithData
                            hasAppointments={hasAppointments}
                            syncSource={syncSource}
                            disabled={shouldDisabled}
                            day={day}
                            currentDelay={currentDelay}
                            onSendAppointments={this.onSendAppointments}
                            onDelayUpdated={this.onDelayUpdated}/>
                        <FormattedMessage {...messages.noAppointmentsYet} />
                    </div>
                );
            } else {
                element = (
                    <div>
                        <Sticky enabled>
                            <div style={{ background: 'white' }}>
                                <ToolbarWithData
                                    hasAppointments={hasAppointments}
                                    disabled={shouldDisabled}
                                    syncSource={syncSource}
                                    day={day}
                                    onSendAppointments={this.onSendAppointments}
                                    onDelayUpdated={this.onDelayUpdated}
                                />
                            </div>
                        </Sticky>
                        <DayTableAppointments
                            multiSelect={this.state.multiSelect}
                            onDeleteAppointment={this.onDeleteAppointment}
                            onUndoCancel={this.onUndoCancel}
                            onCallInAppointment={this.onCallInAppointment}
                            onAppointmentEnded={this.onAppointmentEnded}
                            onAppointmentArrived={this.onAppointmentArrived}
                            onMultiPress={this.onMultiPress}
                            sendDelay={this.sendDelay}
                            day={day}
                            currentDelay={currentDelay}
                            appointments={appointments}
                            displayKupa={doctor.display_kupa}
                        />
                        <hr/>
                    </div>);
            }

            let canceledAppointmentsElement;
            if (canceledAppointments) {
                canceledAppointmentsElement = (
                    <div>
                        <h2>ביטולים</h2>
                        <DayTableAppointments
                            appointments={canceledAppointments}
                            displayKupa={doctor.display_kupa}
                            day={day}
                        />
                    </div>);
            }

            element = (
                <div>
                    {element}
                    <h2>דחופים</h2>
                    <UrgentPatients day={day}/>
                    {canceledAppointmentsElement}
                </div>
            );
        }

        return (
            <div>
                {this.renderModel()}
                {element}
            </div>
        );
    }
}


DayTable.propTypes = {
    appointments: PropTypes.array,
    syncSource: PropTypes.string,
    canceledAppointments: PropTypes.array,
    currentDelay: PropTypes.number,
    day: PropTypes.object.isRequired,
};

export default DayTable;
