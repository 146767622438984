/*
 * PatientsPage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Calendar from 'react-calendar';
import * as qs from 'query-string';
import { firebaseConnect } from 'react-redux-firebase';
import { faCalendarAlt, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { Badge, Container } from "react-bootstrap";

import DayTable from '../../components/DayTable';
import { timerActions } from '../../components/timer';
import messages from './messages';
import { getDoctorDayData } from "../../components/DayTable/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class OutsideAlerter extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.handleClickOutside(event);
    }
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  handleClickOutside: PropTypes.func.isRequired,
};

const selectAuth = () => state => state.firebase.auth;

const formatDay = (day) => timerActions.formatAsPath(day);

const getDayAddressPath = (doctor, day) => {
  const date = formatDay(day);

  return `/days/${doctor.id}/${date}/address`;
};

const selectFirebase = () => state => state.firebase;
const selectDoctor = () => (state) => state.auth.doctor;
const selectDay = () => (state, props) => props.day;

const mapStateToProps = createSelector(
  selectFirebase(),
  selectAuth(),
  selectDoctor(),
  selectDay(),
  (firebase, auth, doctor, day) => ({
    auth,
    address: getDoctorDayData(firebase, doctor, day).address,
    doctor,
  })
);

@connect(mapStateToProps)
@firebaseConnect((props) => [getDayAddressPath(props.doctor, props.day)])
class PatientsPage extends React.Component { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.state = {
      isHovering: false,
    };
  }

  onDayChange(day) {
    const query = { day: timerActions.asLocalTimeIsrael(day).format('YYYYMMDD') };

    this.props.history.replace({search: qs.stringify(query)});
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  render() {
    const { auth, day, address } = this.props;

    let view;
    if (auth) {
      view = (
        <div>
          <DayTable day={day} />
        </div>);
    } else {
      view = (
        <div>
          <span>Please login to continue</span>
        </div>
      );
    }

    const message = timerActions.isToday(day) ? messages.headerToday : messages.header;

    const addressElement = address ? (
      <Badge variant="secondary">
        <FontAwesomeIcon icon={faMapMarker} />&nbsp;<span>{address}</span>
      </Badge>) : <Badge >&nbsp;</Badge>;

    const calendarBtn = <FontAwesomeIcon icon={faCalendarAlt} onClick={this.handleMouseHover}/>;

    const calendarDisplay = this.state.isHovering ? (
      <div style={{ position: 'absolute', zIndex: 10000 }}>
        <OutsideAlerter handleClickOutside={ () => this.setState({ isHovering: false }) }>
          <div>
            <style dangerouslySetInnerHTML={{__html: `
              .react-calendar__month-view__days__day--sunday {
                color: black
              }
            `}} />
            <Calendar
              tileClassName={ ({ date, view }) => view === 'month' && date.getDay() === 0 ? 'react-calendar__month-view__days__day--sunday' : null }
              locale='he-HE'
              calendarType='US'
              onChange={this.onDayChange}
              value={ day } />
          </div>
        </OutsideAlerter>
      </div>) : null;

    return (
      <Container style={{ marginTop: 0 }}>
        <div className="pb-2 mt-4 mb-2 border-bottom" style={{ marginTop: 0, marginBottom: 0 }}>
          <h1>
            <FormattedMessage {...message} />&nbsp;-&nbsp;
            <FormattedDate value={day} weekday="short" day="2-digit" month="2-digit" />&nbsp;
            {calendarBtn}
          </h1>
          {addressElement}
        </div>
        {calendarDisplay}
        <br />
        {view}
      </Container>
    );
  }
}

export default withRouter(PatientsPage);

PatientsPage.propTypes = {
  history: PropTypes.object,
  address: PropTypes.string,
  auth: PropTypes.object,
  day: PropTypes.object,
};
