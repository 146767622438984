import { defineMessages } from 'react-intl';

const scope = 'app.components.MessagesPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'הודעות מערכת',
  },
  noSystemMessages: {
    id: `${scope}.noSystemMessages`,
    defaultMessage: 'לא הוגדרו הודעות מערכת',
  },
  loadingSystemMessages: {
    id: `${scope}.loadingSystemMessages`,
    defaultMessage: 'טוען הודעות מערכת',
  },
  welcome_message: {
    id: `${scope}.welcome_message`,
    defaultMessage: 'הודעה שהפציינט מקבל',
  },
  welcome_message_multiple: {
    id: `${scope}.welcome_message_multiple`,
    defaultMessage: 'הודעה שהפציינט מקבל כשיש לו מספר פגישות ביום',
  },
  facebook_welcome_message_no_appointment: {
    id: `${scope}.facebook_welcome_message_no_appointment`,
    defaultMessage: 'הודעה למשתמש יזום של הבוט (לפני טלפון)',
  },
  welcome_message_no_appointment: {
    id: `${scope}.facebook_welcome_message_no_appointment`,
    defaultMessage: 'הודעה למשתמש יזום של הבוט (לפני טלפון)',
  },
  welcome_message_phone_only: {
    id: `${scope}.welcome_message_phone_only`,
    defaultMessage: 'הודעה על תור טלפוני',
  },
  delay_message: {
    id: `${scope}.delay_message`,
    defaultMessage: 'הודעה על עיכוב',
  },
  no_delay_message: {
    id: `${scope}.no_delay_message`,
    defaultMessage: 'הודעה על סיום העיכוב',
  },
  confirm_cancel_appointment: {
    id: `${scope}.confirm_cancel_appointment`,
    defaultMessage: 'הודעת אישור על ביטול פגישה',
  },
  facebook_welcome_message: {
    id: `${scope}.facebook_welcome_message`,
    defaultMessage: 'הודעה שהפציינט מקבל',
  },
  facebook_welcome_message_multiple: {
    id: `${scope}.facebook_welcome_message_multiple`,
    defaultMessage: 'הודעה שהפציינט מקבל כשיש לו מספר פגישות ביום',
  },
  facebook_auto_reply: {
    id: `${scope}.facebook_auto_reply`,
    defaultMessage: 'הודעה אוטמטית שהמשתמש מקבל',
  },
  facebook_account_link: {
    id: `${scope}.facebook_account_link`,
    defaultMessage: 'הודעה שהמשתמש מקבל לאחר חיבור טלפון',
  },
  waiting_list_welcome: {
    id: `${scope}.waiting_list_welcome`,
    defaultMessage: 'הודעה שהמטופל מקבל כשהוא נכנס לתורים דחופים',
  },
  waiting_list_removed: {
    id: `${scope}.waiting_list_removed`,
    defaultMessage: 'הודעה אישור למטופל שהוא כבר לא בתורים דחופים',
  },
  waiting_list_biding: {
    id: `${scope}.waiting_list_biding`,
    defaultMessage: 'הודעה שהמטופל מקבל כשיש לו תור דחוף אפשרי',
  },
  waiting_list_biding_won: {
    id: `${scope}.waiting_list_biding_won`,
    defaultMessage: 'הודעת אישור על תור דחוף',
  },
  waiting_list_biding_lost: {
    id: `${scope}.waiting_list_biding_lost`,
    defaultMessage: 'הודעה שהתור הדחוף נתפס',
  },
  waiting_list: {
    id: `${scope}.waiting_list`,
    defaultMessage: 'תור דחוף',
  },
  system_messages: {
    id: `${scope}.system_messages`,
    defaultMessage: 'הודעות כלליות',
  },
  no_appointment_today: {
    id: `${scope}.no_appointment_today`,
    defaultMessage: 'הודעה שאין תור',
  },

  sms_im_here: {
    id: `${scope}.sms_im_here_enter`,
    defaultMessage:  "הודעה הכנה לכניסה"
  },

  sms_im_here_enter: {
    id: `${scope}.sms_im_here_enter`,
    defaultMessage:     "הודעה בקשת כניסה למטופל"
  },

  sms_im_here_reply: {
    id: `${scope}.sms_im_here_reply`,
    defaultMessage:  "הודעה תשובה על אישור הגעה"
  },

  sms_im_here_reply_late: {
    id: `${scope}.sms_im_here_reply_late`,
    defaultMessage:  "הודעה תשובה על אישור הגעה מאוחר"
  },


});
