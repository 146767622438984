import React from 'react';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Form from "react-bootstrap/Form";

const customStyles = {
  content : {
    top                   : '25%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class NewPatientDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangedWrap = this.handleChangedWrap.bind(this);

    this.state = { phone: '', name: '', id: '', comments: '' };
  }

  handleChangedWrap(name) {
    return (event) => {
      const data = {};
      data[name] = event.target.value;
      this.setState(data);
    };
  }

  render() {
    const { onClose, onSave } = this.props;

    const shouldDisabled = this.state.name.length === 0 || this.state.phone.length === 0;

    return (
        <Modal isOpen={true} onRequestClose={onClose} style={customStyles}>
          <h1><FormattedMessage {...messages.newPatient} /></h1>
          <Form>
            <Form.Group>
              <Form.Label htmlFor="name"><FormattedMessage {...messages.columnName} /></Form.Label>
              <Form.Control type="text" autoComplete="off" className="form-control" value={this.state.name} onChange={this.handleChangedWrap('name')} name="name" id="name" autoFocus/>
            </Form.Group>
            <Form.Group>
              <Form.Label className="control-label pull-right" htmlFor="id"><FormattedMessage {...messages.columnId} /></Form.Label>
              <Form.Control type="text" autoComplete="off" className="form-control" value={this.state.id} onChange={this.handleChangedWrap('id')} name="id" id="id" />
            </Form.Group>

            <Form.Group>
              <Form.Label className="control-label pull-right" htmlFor="phone"><FormattedMessage {...messages.columnPhone} /></Form.Label>
              <Form.Control type="text" autoComplete="off" className="form-control" value={this.state.phone} onChange={this.handleChangedWrap('phone')} name="phone" id="phone" />
            </Form.Group>

            <Form.Group>
              <Form.Label className="control-label pull-right" htmlFor="comments"><FormattedMessage {...messages.columnComments} /></Form.Label>
              <Form.Control type="text" autoComplete="off" className="form-control" value={this.state.comments} onChange={this.handleChangedWrap('comments')} name="comments" id="comments" />
            </Form.Group>

            <br />
            <Button disabled={shouldDisabled} onClick={() => onSave({
              name: this.state.name,
              id: this.state.id,
              phone: this.state.phone,
              comments: this.state.comments
            })}
            ><FontAwesomeIcon icon={faSave} />&nbsp;<FormattedMessage {...messages.save} /></Button>&nbsp;
            <Button variant="warning" onClick={onClose}>
              <FontAwesomeIcon icon={faTimesCircle} />&nbsp;<FormattedMessage {...messages.close} />
            </Button>
          </Form>
        </Modal>);
  }
}

NewPatientDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NewPatientDialog;
