export const getDoctorDaysData = (firebase, doctor) => {
    if (!firebase || !doctor) {
        return {};
    }

    if (!firebase.data.days) {
        return {};
    }

    return firebase.data.days[ doctor.id ];
};

export const getDoctorDayData = (firebase, doctor, day) => {
    if (!day) {
        return {};
    }

    const doctorDaysData = getDoctorDaysData(firebase, doctor);

    if (!doctorDaysData) {
        return {};
    }

    if (!doctorDaysData[ day.getFullYear() ]) {
        return {};
    }

    if (!doctorDaysData[ day.getFullYear() ][ day.getMonth() + 1 ]) {
        return {};
    }
    
    return doctorDaysData[ day.getFullYear() ][ day.getMonth() + 1 ][ day.getDate() ] || {};
};

export const getUrgentPatients = (firebase, doctor) => {
    if (!firebase || !doctor) {
        return {};
    }

    if (!firebase.data.urgentPatients) {
        return {};
    }

    return firebase.data.urgentPatients[ doctor.id ];
};
