import React from 'react';

const SignUpView = ({ onSubmitUserPassword, onGoogleSignup }) => {
  return (
    <div className="container" style={{dir: "rtl"}}>
      <h1>Sign up</h1>
      <form onSubmit={onSubmitUserPassword} className="form-horizontal">
        <div className="form-group">
          <div className="col-sm-10">
            <input name="email" type="email" id="email" className="form-control" placeholder="Email"/>
          </div>
          <label className="col-sm-2 control-label" htmlFor="email">Email</label>
        </div>
        <div className="form-group">
          <div className="col-sm-10">
            <input name="password" id="password" type="password" className="form-control" placeholder="Password"/>
          </div>
          <label className="col-sm-2 control-label" htmlFor="password">Password</label>
        </div>
        <button type="submit" className="btn btn-default" >Sign Up</button>
        <button type="button" className="btn btn-primary" onClick={onGoogleSignup}>Google</button>
      </form>
    </div>
  );
};

export default SignUpView;
