import produce from 'immer';
import { timerActions } from './actions';

const currentTimeInIsraelJS = () => timerActions.currentTimeInIsrael().toDate();

const currentDayInitialState = { now: currentTimeInIsraelJS() };

const currentDayReducer = (state = currentDayInitialState, action) =>
  produce(state, draft => {
    switch (action.type) {
        /* istanbul ignore next */
      case timerActions.START:
        draft.now = currentTimeInIsraelJS();
        break;

      case timerActions.DAY_CHANGE:
        draft.now = currentTimeInIsraelJS();
        break;
    }
  });


export default currentDayReducer;