import React from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseConnect, useFirebase, isLoaded, firebaseConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { Button, Container, Jumbotron } from "react-bootstrap";

const getUserPath = uid => `users/${uid}`;

const Doctors = ({ doctors }) => {
    const doctorsList = _.map(doctors, (doctor, key) => {
        return { doctor, key }
    }).filter(e => !!e.doctor).map((e) => (
        <div key={e.key}>
            <Link to={`/app/${e.doctor.id}`}>{e.doctor.name}</Link>
            <br/>
        </div>
    )).valueOf();

    return (
        <div>
            {doctorsList}
        </div>
    );
};


Doctors.propTypes = {
    doctors: PropTypes.object.isRequired,
};

// const mainPageWithUidMapStateToProps = createSelector(
//   selectFire),
//   (uid) => ({
//     auth,
//     uid,
//     user: getVal(firebaseApp, getUserPath(uid)),
//   })
// );

function MainPageWithUid({ uid }) {
    const firebase = useFirebase();

    useFirebaseConnect([getUserPath(uid)]);
    const user = useSelector(state => {
        return state.firebase.data.users && state.firebase.data.users[uid];
    });

    let loginOrLogout;

    if (!isLoaded(user)) {
        return (<div/>);
    }

    if (user) {
        const { doctors } = user;
        const doctorsList = _.map(doctors, (doctor, key) => {
            return { doctor, key }
        }).filter(e => !!e.doctor).valueOf();

        const totalDoctors = Object.keys(doctorsList).length;

        if (totalDoctors > 0) {
            if (totalDoctors === 1) {
                const doctorId = doctorsList[ 0 ].doctor.id;

                return (<Redirect to={`/app/${doctorId}`}/>);
            }

            loginOrLogout = (
                <div>
                    <Doctors doctors={doctors}/>
                    <Button onClick={() => firebase.auth().signOut()}>יציאה</Button>
                </div>);
        } else {
            loginOrLogout = <Button onClick={() => firebase.auth().signOut()}>יציאה</Button>
        }
    } else {

    }

    return loginOrLogout;
}

MainPageWithUid.propTypes = {
    uid: PropTypes.string.isRequired,
};


function MainPage() { // eslint-disable-line react/prefer-stateless-function
    const firebase = useFirebase();
    const auth = useSelector(state => state.firebase.auth);

    if (!auth.isLoaded) {
        return <div />;
    }

    function loginWithGoogle() {
        return firebase.login({ provider: 'google', type: 'popup' })
    }

    let uidElement;
    if (auth.isEmpty) {
        uidElement =
            <Button variant="primary" onClick={loginWithGoogle}>
                <FontAwesomeIcon icon={faGoogle}/>&nbsp;
                <span>כניסה</span>
            </Button>;
    } else {
        uidElement = <MainPageWithUid uid={auth.uid} />
    }

    return (
        <Container>
            <div className="span12 vertical-center">
                <Jumbotron>
                    <h1>My Clinic Bot</h1>
                </Jumbotron>
                {uidElement}
            </div>
        </Container>);
}

export default MainPage;